import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Spinner } from 'react-bootstrap';
import { Divider, IconButton, Typography, TextField, InputLabel } from '@material-ui/core';
import {  ReactComponent as CloseIcon} from '../../assets/cancel-outline.svg'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { updateNote, showAlert, deleteNote } from '../../actions/radminUploads/index';
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components';
import Select, { components } from "react-select";

const useStyles = makeStyles({
    dialogWidth:{
        width:'431px'
    },
    dialogContent: {
        fontSize: "16px",
        padding: "13px 0 25px 0",
        fontWeight: "bold"
    },
    title: {
        fontSize: "24px",
        marginBottom: "0px",
        fontWeight: "bold",
        color:'#0C2945',
        fontFamily:'Roboto Condensed'
    },
    actionSection: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        padding: " 0px 25px 25px 25px",
        gap:'19px'
    },
    label: {
        padding: "0 10px",
        fontSize: "14px",
        fontWeight: "600",
    },
    rootButton: {
        backgroundColor: "white",
        width:'181px',
        textTransform:'capitalize',
        color:'#CF3131',
        border:'2px solid #CF3131',
        transition: "all 0.3s ease-in-out",
        "&:hover": {
        border: "2px solid #991212",
        transition: "all 0.3s ease-in-out",
        },

    "&:hover span": {
        color: "#991212",
        fontWeight: "bold",
        transform: "scale(1.1)",
        transition: "transform 0.3s ease-in-out, color 0.3s ease-in-out",
    },
    },
    rootCancelButton: {
        backgroundColor: "#00AEEF",
        width:'181px',
        boxShadow:'none',
        textTransform:'capitalize',
        transition: "all 0.3s ease-in-out",
        color: '#ffffff',
        

    "&:hover": {
        background: "#06739D",
        border: "none",
        transition: "all 0.3s ease-in-out",
    },

    "&:hover span": {
        color: "#FFFFFF",
        fontWeight: "bold",
        transform: "scale(1.1)",
        transition: "transform 0.3s ease-in-out, color 0.3s ease-in-out",
    },
    },
    divider: {
        outerHeight: "5px",
        innerHeight: "5px",
        backgroundColor: "blue",
        width: "100%"
    },
    editNoteTitle: {
        marginTop: '16px',
        fontSize: '14px'
    },
    textField: {
        width: '100%',
        '& .MuiOutlinedInput-input': {
            fontSize: 14,
            padding: '13.5px 14px'
        }
    },
    textArea: {
        width: '100%',
        '& .MuiOutlinedInput-inputMultiline': {
            fontSize: 14,
        },
    }
})

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '25px 25px 0px 25px',
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                    <CloseIcon style={{cursor:'pointer'}}  onClick={onClose} />
            ) : null}
        </MuiDialogTitle>
    );
});


const taskOptions = [
    { value: "call", label: "Call" },
    { value: "fax", label: "Fax" },
    { value: "email", label: "Email" },
    { value: "sms", label: "SMS" },
    { value: "letter", label: "Letter" },
    { value:'general',label:'General'}
  ];
  
  
  
  const recipientOptions = [
    { value:'patient', label:'Patient'},
    { value:'referring_physician', label:'Referring Physician'},
    { value:'internal', label:'Internal'},
    { value:'None', label:'None'}
  ]

  const IndicatorsContainer = (props) => {
    return (
      <>
        <components.Option {...props}>
          <span style={{ marginRight: 5, paddingTop: 5 }}>
            <input
              type="radio"
              checked={props.isSelected}
              onChange={() => null}
              style={{ marginTop: 5 }}
            />{" "}
          </span>
          <label>{props.label}</label>
        </components.Option>
      </>
    );
  };

  
const SelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const SelectDropdownTitle = styled.div`
  margin-bottom: 4px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6D6E6E;
  text-transform: uppercase;
`;



export default function EditNote({ openDialog, editClickHandler, selectedNote, selectedData }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isLoading = useSelector(state => state.radAdmin.notes.isLoading);
    const user = useSelector(state => state.auth.user);
    const [title, setTitle] = useState('');
    const [noteText, setNoteText] = useState("");
    const [clickedButton, setClickedButton] = useState("")
    const [selectedTask, setSelectedTask] = useState("")
    const [selectedRecipient, setSelectedRecipient] = useState("")

       
   const permission = useSelector((state) => state.auth.user.permission_level); 
   const rolePermissions = useSelector(
     (state) =>state.auth.user.permissions
   );
   const updatePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "change_follow-ups")?.is_authorized
   const deletePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "delete_follow-ups")?.is_authorized

    useEffect(() => {
        if (selectedNote !== null) {
            setNoteText(selectedNote?.note_text)
            setTitle(selectedNote?.title)
            setSelectedTask( taskOptions.find(itm=> itm.value === selectedNote?.task) )
            setSelectedRecipient(recipientOptions.find(itm=> itm.value === selectedNote?.recipient))
        }
    }, [selectedNote])

    const saveNoteHandler = () => {
        if((permission && updatePermission) || !permission){
            if (user.id !== selectedNote.user.id) {
                dispatch(showAlert('warning', 'Warning', 'You do not have permission to edit!'))
                return
            }
            setClickedButton('save')
            const data = {
                patient: selectedData?.patientId,
                note_text: noteText,
                title,
                task:selectedTask?.value,
                recipient:selectedRecipient?.value,
            }
            
            if (!data.task) {
                delete data.task;
              }
              if (!data.recipient) {
                delete data.recipient;
              }
    
            dispatch(updateNote(selectedNote.id, data))
                .then(() => editClickHandler())
                window.analytics.track('Follow-Up-List-Edit-Save-Note');
           }else{
             dispatch(
               showAlert('danger', 'Error', `You don't have permission to change reco note`)
             );
           }
       
    }

    const deleteNotHandler = () => {
        if((permission && deletePermission) || !permission){
            if (user.id !== selectedNote.user.id) {
                dispatch(showAlert('warning', 'Warning', 'You do not have permission to delete!'))
                return
            }
            setClickedButton('delete')
            dispatch(deleteNote(selectedNote.id))
                .then(() => editClickHandler())
                window.analytics.track('Follow-Up-List-Delete-Note');
           }else{
             dispatch(
               showAlert('danger', 'Error', `You don't have permission to delete reco note`)
             );
           }
       
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                aria-labelledby="customized-dialog-title"
                maxWidth={20}
                classes={{ paper: classes.dialogWidth }}
            >
                <DialogTitle id="customized-dialog-title" onClose={editClickHandler} >
                    <p className={classes.title}>Edit Note</p>
                </DialogTitle>
                <DialogContent>
                <SelectContainer style={{width:'227px'}}>
                <SelectDropdownTitle> Task</SelectDropdownTitle>
                <Select
                  components={{Option: IndicatorsContainer }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={taskOptions}
                  value={selectedTask}
                  onChange={(value) => setSelectedTask(value)}
                  
                />
              </SelectContainer>
        
           
              <SelectContainer style={{width:'227px'}}>
                <SelectDropdownTitle>Recipient</SelectDropdownTitle>
                <Select
                  components={{Option: IndicatorsContainer }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={recipientOptions}
                  value={selectedRecipient}
                  onChange={(value) => setSelectedRecipient(value)}
                 
                />
              </SelectContainer>
                    <SelectContainer>
                    <SelectDropdownTitle>Note Title *</SelectDropdownTitle>
                    <TextField
                        id="outlined-uncontrolled"
                        variant="outlined"
                        className={classes.textField}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    </SelectContainer>
                   <SelectContainer>
                   <SelectDropdownTitle>Note Content *</SelectDropdownTitle>
                    <TextField
                        id="outlined-uncontrolled"
                        variant="outlined"
                        multiline={true}
                        minRows={6}
                        className={classes.textArea}
                        value={noteText}
                        onChange={(e) => setNoteText(e.target.value)}
                    />
                   </SelectContainer>
                  
                </DialogContent>
                <div className={classes.actionSection}>
                    <Button
                        classes={{ label: classes.label, root: classes.rootButton }}
                        onClick={deleteNotHandler}
                        variant="outlined"
                    >
                        {isLoading && clickedButton === 'delete' ? <Spinner animation="border" role="status" size="sm" /> : "Delete Note"}
                    </Button>
                    <Button
                        classes={{ label: classes.label, root: classes.rootCancelButton }}
                        onClick={saveNoteHandler}
                        isPrimary={true}
                        disabled={!noteText || !title}
                        >
                          <span>
                        {isLoading && clickedButton === 'save' ? <Spinner animation="border" role="status" size="sm" /> : "Save Changes"}
                        </span>
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}

import React, { useState } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import { saveFilterRecommendations, showAlert } from "../../../actions/radminUploads";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";

ReactModal.setAppElement("#root");

const Modal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 386px !important;
  padding: 20px 24px !important;
  height:300px!important;
  margin: 15% auto;
  background: white;

  @media (max-width: 768px) {
    width: auto !important;
    margin: 30px !important;
    margin-top: calc(15% + 30px) !important;
  }
`;

const Label = styled.label`
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3F4857;
`;

const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8692a4;
  height:38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items:center;
  margin-top: 20px;
  gap:20px;
  justify-content:center;
`;

const Button = styled.button`
  width:120px;
  height: 38px;
  background:${({isPrimary, disabled})=> disabled? '#00AEEF4D'
  :isPrimary? '#00AEEF':'transparent' } ;
  border-radius: 5px;
  border: ${({ isPrimary }) =>
    isPrimary? "none" : "2px solid #CFCFCF"};
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({isPrimary})=> isPrimary? 'white':'#000000' };

   &:hover {
    background-color: ${(props) =>
      props.isPrimary ? (props.disabled ? "" : "#06739D") : props.isGrey
      ? "#ffffff": "#CFCFCF"};
    outline: 'none';
    border: ${(props) => (props.isPrimary ? "" : props.isGrey
      ? "2px solid #B4B5B9": "0")};
    color: ${(props) => (props.isPrimary ? "#ffffff": props.isGrey ? "#000000" : "#ffffff")};
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  &:hover span {
    font-weight: bolder;
    transform: ${(props) => (props.disabled ? "none" : "scale(1.1)")};
  }

`;

const customStyle =  {
  overlay: {
      backdropFilter: 'blur(10px)',
      backgroundColor:'rgba(12, 41, 69, 0.38)',
      zIndex:2
  },
  content:{
    borderRadius:'4px'
  }
} ;

const SaveModal = ({
  opened,
  onClose,
  selectedFilters,
  setSelectedValues,
  setShowSaveFilter,
  modalityOperator,
  anatomyOperator,
  reportModalityOperator, 
  examCodeOperator,
  initialExamStartDate,
  initialExamEndDate,
  initialRangeStartDate,
  initialRangeEndDate,
  date,
  selectedOption,
  selectedConfiguration
}) => {
  const [filterSetName, setFilterSetName] = useState("");
  const dispatch = useDispatch();
  const [loading,setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedReco, setIsCheckedReco] = useState(false);
  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "add_follow-ups")?.is_authorized



  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleOnChangeReco = () => {
    setIsCheckedReco(!isCheckedReco);
  };

  const onChangeFilterName = (e) => {
    setFilterSetName(e.target.value);
  };

  const handleFilterSave = async (e) => {
    e.preventDefault();
    if((permission && addPermission) || !permission){
    let list =  Object.values(selectedFilters).map((item, index) => {
        if (!item) return null;
        return item
    }).filter(Boolean).flat(10)
    window.analytics.track('Follow-Up-List-Save-Filter-Set-Submit', {title: filterSetName, list: list});
    setLoading(true);

    const dateObj =  isChecked ? {startDate: initialExamStartDate, endDate: initialExamEndDate, category:'initial_exam_date'} : null

    const recoObj = isCheckedReco ? { category:'initial_reco_range_date'}: null
    if(date && isCheckedReco){
      recoObj.date = date 
      recoObj.selectedOption = selectedOption
      recoObj.selectedConfiguration = selectedConfiguration
    }
    if(initialRangeStartDate && isCheckedReco){
      recoObj.startDate=initialRangeStartDate
      recoObj.selectedOption = selectedOption
      recoObj.selectedConfiguration = selectedConfiguration
    }
    if(initialRangeEndDate && isCheckedReco){
      recoObj.endDate=initialRangeEndDate;
      recoObj.selectedOption = selectedOption
      recoObj.selectedConfiguration = selectedConfiguration
    }

    const listData = [ ...list.map(itm => (itm.category === 'modality' && modalityOperator.value ==='isNotEqual')? ({...itm, negation: 'isNotEqual'})  : (itm.category ==='exam_code' && examCodeOperator.value === 'isNotEqual')? ({...itm, negation: 'isNotEqual'})  : (itm.category === 'anatomy' && anatomyOperator.value === 'isNotEqual') ? ({...itm, negation: 'isNotEqual'}): (itm.category ==='report_modality' && reportModalityOperator.value ==='isNotEqual') ? ({...itm, negation: 'isNotEqual'}): itm )
  ]
   
  if(dateObj){
      listData.push(dateObj);
  }
  if(recoObj){
  listData.push(recoObj);
  }
  
  const res =  await dispatch(saveFilterRecommendations({title: filterSetName, 
      list: listData
  }
    ));
    setSelectedValues({...res, label:res.title, value:res.title})
    setShowSaveFilter(false);
    setLoading(false);
    onClose();
  
    }else{
      dispatch(
        showAlert('danger', 'Error', `You don't have permission to create filter set`)
      );
    }     
   

  };

  return (
    <Modal style={customStyle} isOpen={opened} onRequestClose={onClose} contentLabel="Save Filter Set">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width:'100%',
        }}
      >
        <h2 style={{ fontSize: "24px", color: "#0C2945", fontWeight: 700, fontFamily:'Roboto Condensed' }}>
          Save Filter Set
        </h2>
        <p style={{cursor:'pointer'}} onClick={onClose}>x</p>
      </div>

      <form style={{width:'100%'}} onSubmit={handleFilterSave}>
        <Label children="Filter Set Name" htmlFor="filterSet" />
        <Input
          onChange={onChangeFilterName}
          value={filterSetName}
          id="filterSet"
          placeholder="Filter name"
        />
          <div style={{display:'flex', alignItems:'center', marginTop:'19px', marginBottom:'12px'}}>
    <input
      type="checkbox"
      id="examDate"
      name="examDate"
      value="examDate" 
      checked={isChecked}
      onChange={handleOnChange}
      style={{marginRight:'13px'}}
      disabled={(!initialExamStartDate && !initialExamEndDate)}

      />
    <label style={{fontWeight:400,fontSize:'14px',marginBottom:'0px'}} for="examDate">Include Exam Date Range</label>
  </div>
  <div style={{display:'flex', alignItems:'center'}}>
    <input
      type="checkbox"
      id="recoDate"
      name="recoDate"
      value="recoDate"
      style={{marginRight:'13px'}}
      checked={isCheckedReco}
      onChange={handleOnChangeReco}
      disabled={!date && (!initialRangeStartDate && !initialRangeEndDate)}

      />
    <label for="recoDate"
     style={{fontWeight:400,fontSize:'14px', marginBottom:'0px' }}
     >Include Recommendation Due Date Range</label>
  </div>
        <ButtonContainer>
         <Button isGrey onClick={onClose} children= {<span>Cancel</span>} />
          <Button type="submit" isPrimary={true} disabled={!filterSetName} children={loading?  <Spinner animation="border" role="status" size="sm" />: <span>Save</span>} />
        </ButtonContainer>
      </form>
    </Modal>
  );
};

export default SaveModal;

import React, { useEffect, useState } from 'react';
import { Card, PageTitle } from '../../../styles';
import Table from '../../../components/Table/Table';
import Flex from '../../../components/_styles/Flex';
import {
    getRecentIncomingMessages,
} from '../../../actions/radminUploads';
import Box from '../../../components/_styles/Box';
import { useDispatch, useSelector } from 'react-redux';
import { InfoIcon } from './Icons';
import styled from 'styled-components';
import { EmptyState } from '../../../components/EmptyState/EmptyState';
import { ReactComponent as EmptyStateImg } from '../../../assets/empty-state-search.svg';
import { TemplateButton } from './SmsCreationDialog';
import moment from 'moment';
import MessageContent from './ShowMessageContent';

const Badge = styled(Flex)`
  width: 140px;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 100px;
  color: white;
  background: ${props => {
        switch (props.status) {
            case 'sent':
                return '#FDBB13';
            case 'delivered':
                return '#64C67C';
            case 'bounced':
                return '#FF5F65';
            case 'attempted':
                return '#DFE0EB';
            case 'drafted':
                return '#00AEEF';
            case 'converted':
                return '#FF7A00';
            case 'clicked':
                return '#4EAC9F';
            case 'failed':
                return '#9B64C6';
            case 'undeliverable':
                return '#C2C2C2';
            default:
                return '#DFE0EB';
        }
    }};
`;
export const StatusBadge = ({ status, styles={} }) => <Badge status={status} style={styles}>{status}</Badge>;

const columns = [
    { key: 'created', name: 'Created Timestamped' },
    { key: 'status', name: 'Status', displayCell: cellValue => <StatusBadge status={cellValue} /> },
    { key: 'message_type', name: 'Message Type' },
    { key: 'mrn', name: 'Recipient MRN' },
    { key: 'patient_phone_number', name: 'Recipient Phone Number' },
    { key: 'upload_file', name: '', displayCell: (cellValue) => <TemplateButton width="100"    border={true}
    outlinedBr={"#00AEEF"} color="#00AEEF" title="View Content" icon={<InfoIcon />} ></TemplateButton> },
];

const MessagesDeliveries = () => {
    const messages = useSelector(state => state.radAdmin.messages.recentMessages?.data);
    const isLoading = useSelector(state => state.radAdmin.messages.recentMessages.isLoading);
    const usersInfo = useSelector(state => state.radAdmin.messages.usersInfo?.data);
    const dispatch = useDispatch();
    const [outgoingSms, setOutgoingSms] = useState([]);
    const [messageContent, setMessageContent] = useState("");
    const [twilioPhoneNumber, setTwilioPhoneNumber] = useState("-");
    const [patientPhoneNumber, setPatientPhoneNumber] = useState("-");
    const [showMessageContent, setShowMessageContent] = useState(false)

    useEffect(() => {
        dispatch(getRecentIncomingMessages());
    }, []);

    useEffect(() => {
        if (messages) {
            let arr = []
            for (let i = 0; i < messages.length; i++) {
                messages[i].results.map(x => {
                    if (x.direction === "inbound") {
                        let requiredUser = usersInfo.find(user => user.phone_number === x.patient_phone_number)
                        arr = [...arr, { ...x, created: moment(x.created).format("MM-DD-YYYY"), message_type: "SMS", mrn: requiredUser.mrn || "--" }]
                    }
                })
            }
            setOutgoingSms(arr)
        }
    }, [messages])

    const dialogHandler = () => {
        setShowMessageContent(!showMessageContent)
    }

    const ClickHandler = (value) => {
        setMessageContent(value.message);
        setTwilioPhoneNumber(value.twilio_phone_number)
        setPatientPhoneNumber(value.patient_phone_number)
        setShowMessageContent(!showMessageContent)
    }
    console.log(outgoingSms, "outgoingSms")
    return (
        <Box p={30}>
            <PageTitle>Message Deliveries</PageTitle>
            <Card>
                <Table
                    rows={outgoingSms && outgoingSms}
                    columns={columns}
                    //   initialPageNumber={previousUploads.pageNumber}
                    //   totalItems={previousUploads.totalItems}
                    isLoadingRows={isLoading}
                    clickHandler={(value) => ClickHandler(value)}
                    getRowsAction={(pageNumber) => getRecentIncomingMessages()}
                    noDataRender={() => <EmptyState title={'No Results'} img={() => <EmptyStateImg />} buttonInfo={{ link: '/auth/radAdmin/Import', text: 'Import Data' }}>Sorry, there are no results</EmptyState>}
                />
            </Card>
            <MessageContent 
                messageContent={messageContent}
                show={showMessageContent}
                dialogHandler={dialogHandler}
                patientPhoneNumber={patientPhoneNumber}
                twilioPhoneNumber={twilioPhoneNumber}
            />
        </Box>
    )
};

export default MessagesDeliveries;

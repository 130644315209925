import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Spinner } from 'react-bootstrap';
import { Divider, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux'
import { deleteFollowupList } from '../../actions/radminUploads/index';
import { DEFAULT_VALUES } from '../../pages/RadAdmin/FollowUpList/FollowUpListFilter';

const useStyles = makeStyles({
    dialogContent: {
        fontSize: "16px",
        padding: "13px 0 25px 0",
        fontWeight: "bold"
    },
    title: {
        fontSize: "20px",
        marginBottom: "0px",
        fontWeight: "bold"
    },
    actionSection: {
        display: "flex",
        justifyContent: "space-around",
        width: "70%",
        margin: "0 auto",
        paddingBottom: "20px"
    },
    label: {
        padding: "5px 20px",
        fontSize: "12px",
        fontWeight: "bold"
    },
    rootButton: {
        backgroundColor: " #E55A54",
        height: '47px',
        width: '120px',
        color: '#ffffff',
        '&:hover': {
            background: '#991212',
        },

        'span': {
            transition: 'transform 0.3s ease-in-out !important'
          },
          '&:hover span': {
            fontWeight: 'bolder',
            transform: 'scale(1.1) !important'
          }
    },
    rootCancelButton: {
        backgroundColor: "#00AEEF",
        '&:hover': {
            background: '#06739D',
        },

        'span': {
            transition: 'transform 0.3s ease-in-out !important'
          },
          '&:hover span': {
            fontWeight: 'bolder',
            transform: 'scale(1.1) !important'
          }
    },
    divider: {
        outerHeight: "5px",
        innerHeight: "5px",
        backgroundColor: "blue",
        width: "100%"
    }
})

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function ResponsiveDialog({ openDialog, RemoveRecoHandler, selectedData, editModalHandle }) {
    const classes = useStyles();
    const dispatch = useDispatch();

    const followUpList = useSelector(state => state.radAdmin.followUpList.data);
    const [isLoading, setIsLoading] = useState(false);

    const deleteHandler = () => {
        window.analytics.track('Follow-Up-List-Remove-Reco-Click');
        setIsLoading(true)
        let currentIndex = followUpList.findIndex(reco => reco.id === selectedData.id)
        dispatch(deleteFollowupList(selectedData.id, DEFAULT_VALUES))
            .then(() => {
                closeModalHandle(currentIndex)
                setIsLoading(false)
            })
            .catch(() => setIsLoading(false))
    }

    const closeModalHandle = (currentIndex) => {
        if(currentIndex <= followUpList.length - 1 ){
            let nextReco = followUpList[currentIndex]
            RemoveRecoHandler(nextReco)
        }else {
            RemoveRecoHandler(selectedData)
            editModalHandle()
        }
    }

    return (
        <div>
            <Dialog
                open={openDialog}
                aria-labelledby="customized-dialog-title"
                // maxWidth={20}
            >
                <DialogTitle id="customized-dialog-title" onClose={() => RemoveRecoHandler(selectedData)} style={{ borderBottom: '3px solid #4dabf5' }}>
                    <p className={classes.title}>Delete</p>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <p className={classes.dialogContent}>Are you sure you want to remove this recommendation?</p>
                </DialogContent>
                <div className={classes.actionSection}>
                    <Button
                        classes={{ label: classes.label, root: classes.rootButton }}
                        onClick={deleteHandler}
                    >
                        {isLoading ? <Spinner animation="border" role="status" size="sm" style={{ margin: '0 10px' }} /> : <span>Delete</span>}
                    </Button>
                    <Button
                        classes={{ label: classes.label, root: classes.rootCancelButton }}
                        onClick={() => RemoveRecoHandler(selectedData)}
                        variant="contained"
                        color="primary">
                        Cancel
                    </Button>
                </div>
            </Dialog>
        </div>
    );
}
import React, { useState } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import Table from "../../../components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-followup.svg";
import {
  getSearchReportText,
  getSavedSearchesData,
  clearSearchReportText,
  saveReportSearchText,
  getExportReportAction,
  getReferrersForDropdown,
  getRefName,
  getRadiologistName,
  showAlert,
} from "../../../actions/radminUploads";
import { useEffect } from "react";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { useHistory } from "react-router";
import { capitalize, isEmpty } from "lodash";
import ExportReportsModal from "./ExportReportsModal";
import SearchField from "../SharedComponents/SearchField";
import { Card } from "../../../styles";
import ViewReportModal from "../Messages/ViewReportModal";
import { ReactComponent as ExpandMoreIcon } from "../../../assets/expandMore.svg";
import { ReactComponent as SearchIconCustom } from "../../../assets/searchIconGrey.svg";
import { Filters } from "./Filters";
import { exclude } from "query-string";
import close from "../../../assets/cancel-outline-chip.svg";
import Flex from "../../../components/_styles/Flex";
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";
import SaveModal from "./SaveFilter";
import EditModal from "./EditFilter";
import moment from "moment";
import { Link } from "react-router-dom";
import { ReactComponent as FollowupRecommendedIcon } from '../../../assets/follow-up-recommended-icon.svg';
import { RecommendationTooltip } from "./RecommendationTooltip";

const editIcon = `${process.env.PUBLIC_URL}/${"Edit.svg"}`;

const FilterButton = styled(Flex)`
  width: 41px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #00aeef;
  background: #fff;
  cursor: pointer;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
`;

const StyledEmptyContainer = styled.div`
 display: flex;
 height: 300px;
 justify-content: center;
 align-items: center;
`

const Header = styled.h2`
  font-size: 36px;
  font-weight: 700;
  font-family: Roboto Condensed;
  color: #0c2945;
`;
const Row = styled.div`
  display: flex;
  justify-content: ${(props) => (props.space ? props.space : "space-between")};
  gap: ${(props) => (props.gap ? props.gap : "25px")};
  align-items: ${(props) => (props.alignment ? props.alignment : "center")};
`;

const SelectContainer = styled.div`
  width: ${(props) => props.width || "227px"};
  & div.select__control,
  div.select__value-container {
    height: 38px;
    cursor: pointer;
  }
`;

const SelectDropdownTitle = styled.div`
  margin-bottom: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #6d6e6e;
  text-transform: uppercase;
`;

const Button = styled.button`
  background: ${(props) => props.bg};
  color: ${(props) => props.color};
  outline: none;
  font-size: 14px;
  font-weight: 600;
  border: ${(props) => props.border || "none"};
  height: 38px;
  width: 145px;
  border-radius: 4px;
  cursor:${props=>props.disabled? 'not-allowed': 'pointer'};
  &:hover {
    background: transparent;
    color:#00aeef;
    border: 2px solid #00aeef;
  }
`;

const Container = styled.div`
  padding: 32px 50px;
`;

const SaveSearch = styled.span`
  color: #00aeef;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
`;

export const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  height: 32px;
  margin-top: 2px;
  padding: 4px 10px 4px 10px;
  background: #eeeeee;
  border-radius: 25px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

export const Image = styled.img`
  display: block;
  width: 20px;
`;

export const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #a1a1a1;
`;

export const CloseSign = styled.div`
  width: 7px;
  height: 7px;
  color: #00aeef;
  margin-right: 7px;
  opacity: 1;
  &:hover {
    cursor: pointer;
  }
`;

const StyledLink = styled(Link)`
 color:#00AEEF;
 font-size:14px;
 font-weight:400;
 text-decoration:underline;
`


const IndicatorsContainer = (props) => {
  return (
    <div style={{ backgroundColor: "#FFF" }}>
      <components.IndicatorsContainer {...props} />
    </div>
  );
};

const ReportComponent = ({ reportText, row, rows, idx, hasMoreRecords, onLoadMore,isLoading }) => {
  const [showFullReport, setShowFullReport] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState({
    report_text: row?.report_text,
    accession_number: row?.accession_number,
    initial_exam_date: row?.initial_exam_date,
    modality: row?.reco_modality,
    patient: { mrn: row?.patient_mrn, id: row?.patient_id },
    report_id: row?.id
  })

  const [index,setIndex] = useState(idx)

  const toggleReportVisibility = () => {
    window.analytics.track(
      `Search-Report-Visibility-${
        !showFullReport ? "Expanded" : "Collapsed"
      }-Click`
    );
    setShowFullReport(!showFullReport);
    setShowModal(true);
  };

  useEffect(()=>{
    setData({
      report_text:  rows[index]?.report_text,
      accession_number:  rows[index]?.accession_number,
      initial_exam_date:  rows[index]?.initial_exam_date,
      modality:  rows[index]?.reco_modality,
      patient: { mrn:  rows[index]?.patient_mrn, id:  rows[index]?.patient_id },
      report_id: rows[index]?.id
    })
  },[index, rows])

  return (
    <span>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "21px",
        }}
      >
        <span style={{ maxWidth: "450px" }}>
          {reportText.length >= 100
            ? reportText.slice(0, 100) + "..."
            : reportText}
        </span>
        <ExpandMoreIcon
            style={{ width: 18, height: 18, cursor: "pointer" }}
            onClick={toggleReportVisibility}
          />
        {showModal && (
          <ViewReportModal
            data={data}
            showModal={showModal}
            closeModal={() => {
              setShowModal(false)
              setIndex(idx)
              setData({
                report_text: row?.report_text,
                accession_number: row?.accession_number,
                initial_exam_date: row?.initial_exam_date,
                modality: row?.reco_modality,
                patient: { mrn: row?.patient_mrn, id: row?.patient_id },
                report_id: row?.id
              })
            }}
            handlePrev={()=>{ 
              if(index>0){
                setIndex(prev=>prev -1)
              
              }
            
            }}
            handleNext={()=>{
              if(index === rows.length -1 && hasMoreRecords){
              onLoadMore()
              setIndex(prev=>prev +1)
       
              }
              else if(index<rows.length -1){
                setIndex(prev=>prev +1)
              
              }
             
          }}
          isPaginated={true}
          disabledPrev={index === 0}
          disabledNext={index ===rows.length -1 && !hasMoreRecords}
          isLoading={isLoading}
          showCreateRecommendations={true}
          />
        )}
      </div>
    </span>
  );
};

const SearchReportText = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedValues, setSelectedValues] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedOperators, setSelectedOperators] = useState({
    reportModalityOperator: {
      value: "none",
      label: "none",
      category: "report_modality",
    },
    examCodeOperator: { value: "none", label: "none", category: "exam_code" },
  });

  const searchReportTextList = useSelector(
    (state) => state.radAdmin?.searchReport
  );
  const isLoading = useSelector(
    (state) => state.radAdmin?.searchReport?.isLoading
  );
  const totalItems = useSelector(
    (state) => state.radAdmin?.searchReport?.totalItems
  );

 const permission = useSelector((state) => state.auth.user.permission_level); 
 const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
 const exportPermission = rolePermissions?.find(itm=>itm.name ==="Export")?.permissions.find(itm=>itm.codename === "add_export")?.is_authorized;

  const [page, setPage] = useState(1);
  const [titleForSearch, setTitleForSearch] = useState("");
  const [excludeSearch, setExcludeSearch] = useState("");
  const [opened, setOpened] = useState(false);

  const onClose = () => {
    setOpened(false);
  };

  const SearchReportsColumns = [
    { key: "accession_number", name: "Accession Number", align: "left" },
    {
      key: "patient_mrn",
      name: "Patient MRN",
      displayCell: (patient, row) => (
        <StyledLink
        to={`/auth/radAdmin/followUpList/patients/patientDetails/${row.patient_id}`}
        target='_blank'
        onClick={(e)=>{
          e.stopPropagation();
          window.analytics.track(`Reports-Search-List-Patient-MRN-Click`);
        }}
      >
        {patient}
      </StyledLink>
      ),
      align: "left",
    },
    { key: "reco_modality", name: "Reco Modality", align: "left" },
    { key: "modality", name: "Report Modality", align: "left" },
    { key: "initial_exam_date", name: "Exam Date", align: "left" },
    { key: "follow_up_recommended", name: "Follow-up Recommended", align: "left",  displayCell: (cellValue, rowData) => (
      cellValue?
      <RecommendationTooltip recommendations={rowData.recommendations}>
         <FollowupRecommendedIcon style={{cursor:'pointer'}}/>
      </RecommendationTooltip>
      : '-'
    ),  },
    {
      key: "report_text",
      name: "Report Text",
      displayCell: (report, row, idx,rows) => {
        return <ReportComponent reportText={report} row={row} rows={rows} idx={idx}  hasMoreRecords={totalItems !== searchReportTextList?.data?.length} onLoadMore={onLoadMore} isLoading={isLoading} />;
      },
      align: "left",
    },
  ];
  const query = new URLSearchParams(history.location.search);

  const hasSomeQuery =   query.has("title") ||
  query.has("exclude") ||
  query.has("exam_date_gte") ||
  query.has("exam_date_lte") ||
  query.has("message_sent") ||
  query.has("follow_up_recommended") ||
  query.has("modality") ||
  query.has("location_address") ||
  query.has("exam_code") ||
  query.has("referring_physician_id") ||
  query.has("radiologist_id") ||
  query.has("modality!") ||
  query.has("exam_code!")

  useEffect(() => {

    const fetchData = async () => {
      let title = query.get("title");
      let exclude = query.get("exclude");
      let examDateGteFilter =
        query.getAll("exam_date_gte")?.map((itm) => ({
          value: itm,
          label: itm,
          category: "exam_date_gte",
        })) || "";

      let examDateLteFilter =
        query.getAll("exam_date_lte")?.map((itm) => ({
          value: itm,
          label: itm,
          category: "exam_date_lte",
        })) || "";

      let messageSentFilter =
        query.getAll("message_sent")?.map((itm) => ({
          value: itm,
          label: itm,
          category: "message_sent",
        })) || "";
        let followUpRecommendedFilter =
        query.getAll("follow_up_recommended")?.map((itm) => ({
          value: itm,
          label: itm,
          category: "follow_up_recommended",
        })) || "";
      let modalityFilter =
        query.getAll("modality")?.map((itm) => ({
          value: itm,
          label: itm,
          category: "report_modality",
        })) || "";
      let locationFilter =
        query
          .getAll("location_address")
          ?.map((itm) => ({ value: itm, label: itm, category: "location" })) ||
        "";
      let examCodeFilter =
        query
          .getAll("exam_code")
          ?.map((itm) => ({ value: itm, label: itm, category: "exam_code" })) ||
        "";
      let referrerFilter =
        query
          .getAll("referring_physician_id")
          ?.map((itm) => ({ value: itm, label: itm, category: "referrers" })) ||
        "";
      let radiologistFilter =
        query.getAll("radiologist_id")?.map((itm) => ({
          value: itm,
          label: itm,
          category: "radiologist",
        })) || "";
      if (referrerFilter.length > 0) {
        try {
          const updatedFilter = await Promise.all(
            referrerFilter.map(async (itm) => {
              const res = await dispatch(getRefName(itm.value));
              if (res) {
                return {
                  ...itm,
                  label: res.first_name,
                };
              }
              return itm;
            })
          );
          referrerFilter = updatedFilter;
        } catch (error) {
          console.log(error);
        }
      }

      if (radiologistFilter.length > 0) {
        try {
          const updatedFilter = await Promise.all(
            radiologistFilter.map(async (itm) => {
              const res = await dispatch(getRadiologistName(itm.value));
              if (res) {
                return {
                  ...itm,
                  label: res.first_name,
                };
              }
              return itm;
            })
          );
          radiologistFilter = updatedFilter;
        } catch (error) {
          console.log(error);
        }
      }

      let negatedModalityFilter =
        query.getAll("modality!")?.map((itm) => ({
          value: itm,
          label: itm,
          category: "report_modality",
        })) || "";
      let negatedExamCodeFilter =
        query
          .getAll("exam_code!")
          ?.map((itm) => ({ value: itm, label: itm, category: "exam_code" })) ||
        "";

      if (negatedExamCodeFilter.length > 0) {
        setSelectedOperators((prev) => ({
          ...prev,
          examCodeOperator: {
            value: "isNotEqual",
            label: "is not equal to",
            category: "exam_code",
          },
        }));
      }
      if (negatedModalityFilter.length > 0) {
        setSelectedOperators((prev) => ({
          ...prev,
          reportModalityOperator: {
            value: "isNotEqual",
            label: "is not equal to",
            category: "report_modality",
          },
        }));
      }

      setTitleForSearch(title);
      setExcludeSearch(exclude);

      setSelectedFilters({
        radiologist: radiologistFilter,
        referrers: referrerFilter,
        message_sent: messageSentFilter,
        follow_up_recommended: followUpRecommendedFilter,
        location: locationFilter,
        report_modality:
          modalityFilter?.length > 0 ? modalityFilter : negatedModalityFilter,
        exam_code:
          examCodeFilter?.length > 0 ? examCodeFilter : negatedExamCodeFilter,
        exam_date:
          examDateGteFilter.length > 0 && examDateLteFilter.length > 0
            ? [
                {
                  label: `${moment(examDateGteFilter?.[0]?.value).format(
                    "YYYY-MM-DD"
                  )} - ${moment(examDateLteFilter?.[0]?.value).format(
                    "YYYY-MM-DD"
                  )}`,
                  value: `${moment(examDateGteFilter?.[0]?.value).format(
                    "YYYY-MM-DD"
                  )} - ${moment(examDateLteFilter?.[0]?.value).format(
                    "YYYY-MM-DD"
                  )}`,
                  category: "exam_date",
                  startDate: new Date(examDateGteFilter?.[0]?.value),
                  endDate: new Date(examDateLteFilter?.[0]?.value),
                },
              ]
            : [],
      });

      dispatch(
        getSearchReportText(1, title, exclude, {
          exam_date:
            examDateGteFilter.length > 0 && examDateLteFilter.length > 0
              ? [
                  {
                    label: `${moment(examDateGteFilter?.[0]?.value).format(
                      "YYYY-MM-DD"
                    )} - ${moment(examDateLteFilter?.[0]?.value).format(
                      "YYYY-MM-DD"
                    )}`,
                    value: `${moment(examDateGteFilter?.[0]?.value).format(
                      "YYYY-MM-DD"
                    )} - ${moment(examDateLteFilter?.[0]?.value).format(
                      "YYYY-MM-DD"
                    )}`,
                    category: "exam_date",
                    startDate: new Date(examDateGteFilter?.[0]?.value),
                    endDate: new Date(examDateLteFilter?.[0]?.value),
                  },
                ]
              : [],
          message_sent: messageSentFilter,
          follow_up_recommended: followUpRecommendedFilter,
          report_modality:
            modalityFilter?.length > 0 ? modalityFilter : negatedModalityFilter,
          location: locationFilter,
          exam_code:
            examCodeFilter?.length > 0 ? examCodeFilter : negatedExamCodeFilter,
          referrers: referrerFilter,
          radiologist: radiologistFilter,
          modalityNegation:
            negatedModalityFilter.length > 0
              ? {
                  value: "isNotEqual",
                  label: "is not equal to",
                  category: "report_modality",
                }
              : selectedOperators.reportModalityOperator,
          examCodeNegation:
            negatedExamCodeFilter.length > 0
              ? {
                  value: "isNotEqual",
                  label: "is not equal to",
                  category: "exam_code",
                }
              : selectedOperators.examCodeOperator,
        })
      );
    };

    if (
    hasSomeQuery
    ) {
          
    fetchData();
    }
    return ()=>{
      dispatch(
        clearSearchReportText()
      );
    }
  }, []);

  const exportReportsHandler = () => {
    if((permission && exportPermission) || !permission){
      window.analytics.track("Export-All-Reports-Button-Click");
      setShowModal(true);
   }else{
     dispatch(
       showAlert('danger', 'Error', `You don't have permission to export reports`)
     );
   }
  };

  const onLoadMore = () => {
    dispatch(
      getSearchReportText(
        page + 1,
        titleForSearch || selectedValues?.value,
        excludeSearch || selectedValues?.exclude,
        selectedFilters
      )
    );
    setPage(page + 1);
  };

  const [savedSearches, setSavedSearches] = useState([]);

  const [loadingMore, setLoadingMore] = useState(false);
  const [nextPage, setNextPage] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [editing, setEditing] = useState({ visible: false, data: {} });

  useEffect(() => {
    dispatch(getSavedSearchesData(1)).then((res) => {
      if (res) {
        setSavedSearches(
          res?.results?.map((itm) => ({
            label: itm.name || itm.search_text,
            value: itm.id,
            category: "search",
            exclude: itm.neg_search_text,
            search: itm.search_text,
            id: itm.id,
            filters: itm.filters || [],
          }))
        );
        if (res?.next) {
          setNextPage(2);
        }
      }
    });
  }, []);

  const loadMoreOptions = () => {
    if (nextPage && !loadingMore) {
      setLoadingMore(true);
      dispatch(getSavedSearchesData(nextPage)).then((res) => {
        setLoadingMore(false);
        if (res) {
          setSavedSearches((prev) => [
            ...prev,
            ...res?.results?.map((itm) => ({
              label: itm?.name || itm.search_text,
              value: itm.id,
              search: itm.search_text,
              category: "search",
              exclude: itm.neg_search_text,
              id: itm.id,
              filters: itm.filters || [],
            })),
          ]);
        }
        if (res?.next) {
          setNextPage((prev) => prev + 1);
        } else {
          setNextPage();
        }
      });
    }
  };

  const Option = (props) => {
    return (
      <>
        <components.Option {...props}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              {" "}
              <span style={{ marginRight: 5, paddingTop: 5 }}>
                <input
                  type="radio"
                  checked={props.isSelected}
                  onChange={() => null}
                  style={{ marginTop: 5, cursor: "pointer" }}
                />{" "}
              </span>
              <label style={{ cursor: "pointer" }}>{props.label}</label>
            </div>
            <div>
              <img
                height="14px"
                width="14px"
                src={editIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditing({ visible: true, type: props.data });
                }}
              />
            </div>
          </div>
        </components.Option>
      </>
    );
  };

  const closeSelected = (category, selectedItem) => {
    setPage(1);
    const filters = selectedFilters[category].filter((item) => {
      return item.value !== selectedItem.value;
    });
    let newFilters = {
      ...selectedFilters,
      [category]: filters.length > 0 ? filters : [],
      modalityNegation: selectedOperators.reportModalityOperator,
      examCodeNegation: selectedOperators.examCodeOperator,
    };
    setSelectedFilters(newFilters);

    let params = new URLSearchParams(history.location.search);

    params.delete("message_sent");
    params.delete("radiologist_id");
    params.delete("referring_physician_id");
    params.delete("location_address");
    params.delete("modality");
    params.delete("modality!");
    params.delete("exam_code");
    params.delete("exam_code!");
    params.delete("exam_date_gte");
    params.delete("exam_date_lte");
    params.delete("follow_up_recommended");

    if (newFilters.exam_date?.length > 0) {
      newFilters.exam_date.forEach((itm) => {
        params.append(
          "exam_date_gte",
          moment(itm.startDate).format("YYYY-MM-DD")
        );
        params.append(
          "exam_date_lte",
          moment(itm.endDate).format("YYYY-MM-DD")
        );
      });
    }

    if (newFilters.message_sent?.length > 0) {
      newFilters.message_sent.forEach((itm) => {
        params.append("message_sent", itm.value);
      });
    }
    if (newFilters.follow_up_recommended?.length > 0) {
      newFilters.follow_up_recommended.forEach((itm) => {
        params.append("follow_up_recommended", itm.value);
      });
    }

    if (newFilters.radiologist?.length > 0) {
      newFilters.radiologist.forEach((itm) => {
        params.append("radiologist_id", itm.value);
      });
    }

    if (newFilters.referrers?.length > 0) {
      newFilters.referrers.forEach((itm) => {
        params.append("referring_physician_id", itm.value);
      });
    }

    if (newFilters.location?.length > 0) {
      newFilters.location.forEach((itm) => {
        params.append("location_address", itm.value);
      });
    }
    if (newFilters.report_modality?.length > 0) {
      newFilters.report_modality.forEach((itm) => {
        if (newFilters?.modalityNegation?.value === "isNotEqual") {
          params.append("modality!", itm.value);
        } else {
          params.append("modality", itm.value);
        }
      });
    }
    if (newFilters.exam_code?.length > 0) {
      newFilters.exam_code.forEach((itm) => {
        if (newFilters?.examCodeNegation?.value === "isNotEqual") {
          params.append("exam_code!", itm.value);
        } else {
          params.append("exam_code", itm.value);
        }
      });
    }

    history.replace({
      search: params.toString(),
    });
    if(hasNonEmptyArray(getChips(newFilters)) || titleForSearch){
      dispatch(getSearchReportText(1, titleForSearch, excludeSearch, newFilters));

    }else{
      dispatch(
        clearSearchReportText()
      );
    }
    
  };

  const getChips = (selectedFilters) =>{
  return  Object.values(selectedFilters).map((item, index) => {
      if (!item || !Array.isArray(item)) return null;
      return item.map((i, idx) => (
        <SelectedItemContainer key={index + idx}>
          <SelectedItem>
            {" "}
            {i.category
              .replace(/_/g, " ")
              .replace(/\b\w/g, function (char) {
                return char.toUpperCase();
              })}{" "}
            :{" "}
            {((i.category === "report_modality" &&
              selectedOperators.reportModalityOperator.value ===
                "isNotEqual") ||
              (i.category === "exam_code" &&
                selectedOperators.examCodeOperator.value ===
                  "isNotEqual")) &&
              "Is Not"}{" "}
            {i.category === "referrers" || i.category === "radiologist"
              ? i.label
              : i.value.replace(/\b\w/g, function (char) {
                  return char.toUpperCase();
                })}
          </SelectedItem>
          <CloseSign onClick={() => closeSelected(i.category, i)}>
            <Image src={close} alt={"close sign"} />
          </CloseSign>
        </SelectedItemContainer>
      ));
    })
  }

  function hasNonEmptyArray(items) {
    return items.some(
      (subArray) => Array.isArray(subArray) && subArray.length > 0
    );
  }
  


  return (
    <Container>
      <Row style={{ marginBottom: "28px" }}>
        <Header>Search Report Text</Header>
        <div style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}>
          <FilterButton onClick={() => setShowFilters(true)}>
            <Image src={filterOutline} alt={"settings icon"} />
          </FilterButton>
          <div>
            <SelectDropdownTitle>Saved Searches</SelectDropdownTitle>
            <Row>
              <SelectContainer width="336px">
                <Select
                  components={{ Option }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={savedSearches}
                  value={selectedValues}
                  onChange={(option) => {
                    if (!option) {
                      setSelectedFilters([]);
                      setSelectedOperators({
                        reportModalityOperator: {
                          value: "none",
                          label: "none",
                          category: "report_modality",
                        },
                        examCodeOperator: {
                          value: "none",
                          label: "none",
                          category: "exam_code",
                        },
                      });
                    }
                    setSelectedValues(option);
                    setPage(1);
                    history.push({
                      search: ``,
                    });
                    let filters;
                    if (option?.filters.length > 0 && option) {
                      filters = {
                        exam_date: option?.filters
                          ?.filter((itm) => itm.category === "exam_date")
                          .map((itm) => ({
                            ...itm,
                            endDate: new Date(itm.endDate),
                            startDate: new Date(itm.startDate),
                          })),
                        radiologist: option?.filters?.filter(
                          (itm) => itm.category === "radiologist"
                        ),
                        referrers: option?.filters?.filter(
                          (itm) => itm.category === "referrers"
                        ),
                        message_sent: option?.filters?.filter(
                          (itm) => itm.category === "message_sent"
                        ),
                        follow_up_recommended: option?.filters?.filter(
                          (itm) => itm.category === "follow_up_recommended"
                        ),
                        location: option?.filters?.filter(
                          (itm) => itm.category === "location"
                        ),
                        report_modality: option?.filters?.filter(
                          (itm) => itm.category === "report_modality"
                        ),
                        exam_code: option?.filters?.filter(
                          (itm) => itm.category === "exam_code"
                        ),
                        modalityNegation:
                          option?.filters?.filter(
                            (itm) => itm.category === "report_modality"
                          )[0]?.negation === "isNotEqual"
                            ? {
                                value: "isNotEqual",
                                label: "is not equal to",
                                category: "report_modality",
                              }
                            : {
                                value: "none",
                                label: "none",
                                category: "report_modality",
                              },
                        examCodeNegation:
                          option?.filters?.filter(
                            (itm) => itm.category === "exam_code"
                          )[0]?.negation === "isNotEqual"
                            ? {
                                value: "isNotEqual",
                                label: "is not equal to",
                                category: "exam_code",
                              }
                            : {
                                value: "none",
                                label: "none",
                                category: "exam_code",
                              },
                      };

                      if (
                        option?.filters?.filter(
                          (itm) => itm.category === "exam_code"
                        ).length > 0 &&
                        option?.filters?.filter(
                          (itm) => itm.category === "exam_code"
                        )[0]?.negation === "isNotEqual"
                      ) {
                        setSelectedOperators((prev) => ({
                          ...prev,
                          examCodeOperator: {
                            value: "isNotEqual",
                            label: "is not equal to",
                            category: "exam_code",
                          },
                        }));
                      }
                      if (
                        option?.filters?.filter(
                          (itm) => itm.category === "report_modality"
                        ).length > 0 &&
                        option?.filters?.filter(
                          (itm) => itm.category === "report_modality"
                        )[0]?.negation === "isNotEqual"
                      ) {
                        setSelectedOperators((prev) => ({
                          ...prev,
                          reportModalityOperator: {
                            value: "isNotEqual",
                            label: "is not equal to",
                            category: "report_modality",
                          },
                        }));
                      }

                      setSelectedFilters(filters);
                    }
                    setTitleForSearch(option?.search ?? "");
                    setExcludeSearch(option?.exclude ?? "");
                    dispatch(
                      getSearchReportText(
                        1,
                        option?.search,
                        option?.exclude,
                        filters
                      )
                    );

                    let params = new URLSearchParams(history.location.search);
                    params.delete("message_sent");
                    params.delete("follow_up_recommended");
                    params.delete("radiologist_id");
                    params.delete("referring_physician_id");
                    params.delete("location_address");
                    params.delete("modality");
                    params.delete("modality!");
                    params.delete("exam_code");
                    params.delete("exam_code!");
                    params.delete("exam_date_gte");
                    params.delete("exam_date_lte");
                    params.delete("title");
                    params.delete("exclude");

                    if (option?.search) {
                      params.append("title", option?.search);
                    }

                    if (option?.exclude) {
                      params.append("exclude", option?.exclude);
                    }

                    if (filters?.exam_date?.length > 0) {
                      filters.exam_date.forEach((itm) => {
                        params.append(
                          "exam_date_gte",
                          moment(itm.startDate).format("YYYY-MM-DD")
                        );
                        params.append(
                          "exam_date_lte",
                          moment(itm.endDate).format("YYYY-MM-DD")
                        );
                      });
                    }

                    if (filters?.message_sent?.length > 0) {
                      filters.message_sent.forEach((itm) => {
                        params.append("message_sent", itm.value);
                      });
                    }

                    if (filters?.follow_up_recommended?.length > 0) {
                      filters.follow_up_recommended.forEach((itm) => {
                        params.append("follow_up_recommended", itm.value);
                      });
                    }

                    if (filters?.radiologist?.length > 0) {
                      filters.radiologist.forEach((itm) => {
                        params.append("radiologist_id", itm.value);
                      });
                    }

                    if (filters?.referrers?.length > 0) {
                      filters.referrers.forEach((itm) => {
                        params.append("referring_physician_id", itm.value);
                      });
                    }

                    if (filters?.location?.length > 0) {
                      filters.location.forEach((itm) => {
                        params.append("location_address", itm.value);
                      });
                    }
                    if (filters?.report_modality?.length > 0) {
                      filters.report_modality.forEach((itm) => {
                        if (filters?.modalityNegation?.value === "isNotEqual") {
                          params.append("modality!", itm.value);
                        } else {
                          params.append("modality", itm.value);
                        }
                      });
                    }
                    if (filters?.exam_code?.length > 0) {
                      filters.exam_code.forEach((itm) => {
                        if (filters?.examCodeNegation?.value === "isNotEqual") {
                          params.append("exam_code!", itm.value);
                        } else {
                          params.append("exam_code", itm.value);
                        }
                      });
                    }

                    history.replace({
                      search: params.toString(),
                    });
                  }}
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      backgroundColor: "white",
                      padding: "0px 7px",
                    }),
                    option: (styles) => ({
                      ...styles,
                      fontSize: "14px",
                      cursor: "pointer",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "#828282",
                      fontSize: "14px",
                      fontWeight: 400,
                      fontFamily: "Open Sans",
                      marginLeft: "0px",
                    }),
                  }}
                  isLoading={loadingMore}
                  onMenuScrollToBottom={loadMoreOptions}
                  isClearable={true}
                />
              </SelectContainer>
              <Button
                bg="transparent"
                color="#00AEEF"
                border="2px solid #00AEEF"
                onClick={exportReportsHandler}
              >
                Export All Reports
              </Button>
            </Row>
          </div>
        </div>
      </Row>
      <Row
        style={{
          justifyContent: "flex-end",
          marginBottom: "20px",
          gap: "6px",
          flexWrap: "wrap",
        }}
      >
        {getChips(selectedFilters)}
      </Row>
      <Row style={{ marginBottom: "23px" }} space="flex-start">
        <div>
          <SelectDropdownTitle>CONTAINS *</SelectDropdownTitle>
          <Row space="flex-start" gap="29px">
            <SearchField
              placeholder="Type to search..."
              value={titleForSearch}
              onChangeHandle={(e) => {
                setTitleForSearch(e.target.value);
                setShowSaveButton(true);
                let params = new URLSearchParams(history.location.search);
                if(e.target.value){
                  params.set("title", e.target.value);
                }else{
                  params.delete("title");

                }

                if(!e.target.value){
                 setExcludeSearch("")
                }

                if (params.has("exclude")) {
                  params.delete(["exclude"]);
                  history.replace({
                    search: params.toString(),
                  });
                }
                history.push({
                  search: params.toString(),
                });
              }}
              onSearchEnter={() => {}}
              onReset={() => {
                if(hasNonEmptyArray(getChips(selectedFilters))){
                  dispatch(
                    getSearchReportText(1, "", "", selectedFilters)
                  );
                }else{
                  dispatch(
                    clearSearchReportText()
                  );
                }
                
                  setPage(1);
                  setTitleForSearch("");
                  setExcludeSearch("");
                  let queryParams = new URLSearchParams(
                    history.location.search
                  );

                  if (queryParams.has("title")) {
                    queryParams.delete(["title"]);
                    history.replace({
                      search: queryParams.toString(),
                    });
                  }
                  if (queryParams.has("exclude")) {
                    queryParams.delete(["exclude"]);
                    history.replace({
                      search: queryParams.toString(),
                    });
                  }
              }}
              styles={{
                marginRight: 0,
                marginBottom: 0,
                width: "618px",
                position: "relative",
              }}
              placeholderStyles={{
                color: "#828282",
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "Open Sans",
                marginLeft: "32px",
              }}
              inputStyles={{
                background: "#FFFFFF",
                border: "1px solid #D2D3D7",
                textAlign: "left",
                paddingLeft: "20px",
                height:'38px'
              }}
              searchIconStyles={{
                marginLeft: "12px",
              }}
              clearIconStyle={{
                right: 20,
                marginLeft: 0,
              }}
              SearchIconCustom={<SearchIconCustom />}
            />
          </Row>
        </div>
        <div>
          <SelectDropdownTitle>DOES NOT CONTAIN</SelectDropdownTitle>
          <Row space="flex-start" gap="29px">
            <SearchField
              placeholder="e.g. no not"
              value={excludeSearch}
              disabled={!titleForSearch}
              onChangeHandle={(e) => {
                setExcludeSearch(e.target.value);
                let params = new URLSearchParams(history.location.search);
                if(e.target.value){
                  params.set("exclude", e.target.value);
                }else{
                  params.delete("exclude");
                }

                history.push({
                  search: params.toString(),
                });
              }}
              onSearchEnter={() => {}}
              onReset={() => {
                if (excludeSearch) {
                  dispatch(
                    getSearchReportText(1, titleForSearch, "", selectedFilters)
                  );
                  setPage(1);
                  setExcludeSearch("");
                  let queryParams = new URLSearchParams(
                    history.location.search
                  );

                  if (queryParams.has("exclude")) {
                    queryParams.delete(["exclude"]);
                    history.replace({
                      search: queryParams.toString(),
                    });
                  }
                }
              }}
              styles={{
                marginRight: 0,
                marginBottom: 0,
                width: "318px",
                position: "relative",
              }}
              placeholderStyles={{
                color: "#828282",
                fontWeight: 400,
                fontSize: "16px",
                fontFamily: "Open Sans",
                marginLeft: "32px",
              }}
              inputStyles={{
                background: !titleForSearch? "#F4F4F4"  :  "#FFFFFF",
                border: "1px solid #D2D3D7",
                textAlign: "left",
                paddingLeft: "20px",
                height:'38px'
              }}
              searchIconStyles={{
                marginLeft: "12px",
              }}
              clearIconStyle={{
                right: 20,
                marginLeft: 0,
              }}
              SearchIconCustom={<SearchIconCustom />}
            />
            <Button
              bg="#00AEEF"
              color="#FFF"
              disabled={(selectedValues && !titleForSearch) || !titleForSearch }
              onClick={() => {
                setSelectedValues(null);
                setPage(1);
                dispatch(
                  getSearchReportText(
                    1,
                    titleForSearch,
                    excludeSearch,
                    selectedFilters
                  )
                );
              }}
            >
              Search
            </Button>
            {titleForSearch && showSaveButton && (
              <SaveSearch
                onClick={() => {
                  window.analytics.track(
                    "Report-Search-Text-Save-Search-Button-Click"
                  );
                  // setShowSaveButton(false);
                  setOpened(true);
                  // dispatch(
                  //   saveReportSearchText(titleForSearch, excludeSearch)
                  // ).then((res) => {
                  //   dispatch(getSavedSearchesData(1)).then((res) => {
                  //     setSavedSearches(
                  //       res?.results?.map((itm) => ({
                  //         label: itm.search_text,
                  //         value: itm.search_text,
                  //         category: "search",
                  //       }))
                  //     );
                  //     if (res?.next) {
                  //       setNextPage(2);
                  //     }
                  //   });
                  // });
                }}
              >
                Save Search
              </SaveSearch>
            )}
          </Row>
        </div>
      </Row>
      <Row>
        {!isLoading && (
          <span style={{ fontWeight: 600, fontSize: "12px", color: "#9FA2B4" }}>
            {totalItems ?? 0} results
          </span>
        )}
      </Row>
      <Card>
        <>
        { totalItems === null && !isLoading? 
         <StyledEmptyContainer>
          <span>Type search or select filters to fetch results</span>
         </StyledEmptyContainer>
        :

        <>
          <Table
            rows={searchReportTextList?.data}
            initialPageNumber={searchReportTextList?.pageNumber}
            columns={SearchReportsColumns}
            totalItems={totalItems}
            isLoadingRows={isLoading && page < 2}
            noDataRender={() => (
              <EmptyState
                title={"No Results"}
                img={() => <EmptyStateImg />}
                imageContainerStyles={{ width: "73px", height: "81px" }}
              >
                Sorry, there are no results
              </EmptyState>
            )}
            shouldGetRowActions={false}
          />
          {searchReportTextList?.data &&
            searchReportTextList?.data.length !== 0 && (
              <LoadMoreButton
                title="Load more reports"
                handleClick={onLoadMore}
                isDisabled={totalItems === searchReportTextList?.data?.length}
                isLoading={isLoading}
                style={{
                  backgroundColor: "#0c2945",
                  fontWeight: 600,
                }}
              />
            )}
            </>
           }
        </>
      </Card>

      {showModal && (
        <ExportReportsModal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
        />
      )}
      {showFilters && (
        <Filters
          showModal={showFilters}
          setPage={setPage}
          title={titleForSearch}
          exclude={excludeSearch}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          onClose={() => setShowFilters(false)}
          selectedOperators={selectedOperators}
          setSelectedOperators={setSelectedOperators}
        />
      )}
      {opened && (
        <SaveModal
          setSelectedValues={setSelectedValues}
          selectedFilters={selectedFilters}
          opened={opened}
          onClose={onClose}
          titleForSearch={titleForSearch}
          excludeSearch={excludeSearch}
          setShowSaveButton={setShowSaveButton}
          setSavedSearches={setSavedSearches}
          setNextPage={setNextPage}
          selectedOperators={selectedOperators}
          // onSave={handleSearchSave}
          // setShowSaveFilter={setShowSaveFilter}
          // modalityOperator={modalityOperator}
          // anatomyOperator={anatomyOperator}
          // reportModalityOperator={reportModalityOperator}
          // examCodeOperator={examCodeOperator}
        />
      )}

      {editing.visible && (
        <EditModal
          selectedValues={editing.type}
          opened={editing.visible}
          onClose={() => setEditing({ visible: false, type: {} })}
          setSavedSearches={setSavedSearches}
          setNextPage={setNextPage}
        />
      )}
    </Container>
  );
};

export default SearchReportText;

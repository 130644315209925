import React, { useState, useEffect } from "react";
import { Card, PageTitle } from "../../../styles";
import Table from "../../../components/Table/Table";
import {
  getRadminExports,
  getExportMessages,
  getExportNotes,
  getExportEmails,
  getExportFaxes
} from "../../../actions/radminExports";
import Box from "../../../components/_styles/Box";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { useHistory } from "react-router";
import LoadMoreButton from "../../../components/LoadMoreButton";
import Flex from "../../../components/_styles/Flex";

const DownloadFileLink = styled.a`
  padding: 10px;
  background: #00aeef;
  color: white;
  border-radius: 5px;
  text-align: center;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
  &.disabled {
    background: grey;
    cursor: not-allowed;
    &:hover {
    color: white;
    }
  }
`;
const SwitchContainer = styled.div`
  padding: 4px;
  display: flex;
  background: #e6e9f1;
  border-radius: 8px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0c2945;
  width:fit-content;
`;

const SwitchButton = styled.button`
  padding: 7px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? "#0C2945" : "#E6E9F1")};
  color: ${(props) => (props.active ? "#F9FAFC" : "#0C2945")};
  font-weight: ${(props) => (props.active ? 700 : 600)};
  border: 0;
  outline: 0;
  border-radius: 6px;
`;
const Headercontainer = styled.div`
  display: flex;
  flex-direction:column;
  gap:30px;
`;

function formatStatus(status) {
  if (status.includes('_')) {
    return status.replace(/_/g, '-');
  }
  return status;
}

const Badge = styled(Flex)`
  width: 100px;
  min-width: 54px;
  padding: 0 5px;
  height: 24px;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  border-radius: 100px;
  color: white;
  background: ${(props) => {
    switch (props.status) {
      case "created":
        return "#FDBB13";
      case "in_progress":
        return "#FDBB13";
      case "ready":
        return "#64C67C";
      case "error":
        return "#CF3131";
    }
  }};
`;
const StatusBadge = ({ status }) => (
  <Badge status={status}>{formatStatus(status)}</Badge>
);

const downloadClickAnalytic = (resource) => {
  window.analytics.track(`ExportHistory-Download-${resource}-Click`);
};
const columns = [
  { key: "id", name: "File ID" },
  { key: "status",
    name: "Status",
    displayCell: (status) => <StatusBadge status={status} />
  },
  { key: "created", name: "Created",
    displayCell: (created) => <div>{created || '-'}</div>
   },
  { key: "initial_exam_date_greater_than_filter", name: "Time Range Start",  displayCell: (cellValue, row) => {
    return (
    <span>{row?.initial_exam_date_greater_than_filter || row?.nlp_range_start_greater_than_filter || row?.nlp_range_end_greater_than_filter || '-'}</span>
  ) }},
  { key: "initial_exam_date_less_than_filter", name: "Time Range End",  displayCell: (cellValue, row) => {
    return (
    <span>{row?.initial_exam_date_less_than_filter || row?.nlp_range_start_less_than_filter || row?.nlp_range_end_less_than_filter || '-'}</span>
  ) }},  {
    key: "file",
    name: "Download Link",
    displayCell: (cellValue, row) => (
      <DownloadFileLink
        href={cellValue}
        download
        className={row?.status !== 'ready' ? 'disabled' : ''}
        onClick={()=>downloadClickAnalytic("Reports")}
      >
        Download
      </DownloadFileLink>
    ),
  },
];

const messageColumns = [
  { key: "id", name: "File ID" },
  { key: "status", name: "Status" },
  { key: "created", name: "Created Date" },
  { key: "timeRange", name: "Timerange Filters" },
  {
    key: "file",
    name: "Link to Download ",
    displayCell: (cellValue) => (
      <DownloadFileLink
        href={cellValue}
        download
        onClick={()=>downloadClickAnalytic('Messages')}
      >
        Download
      </DownloadFileLink>
    ),
  },
];

const notesColumns = [
  { key: "id", name: "File ID" },
  { key: "status", name: "Status" },
  {
    key: "user",
    name: "User",
  },
  {
    key: "created",
    name: "Created Date",
  },
  {
    key: "title_filter",
    name: "Title",
  },
  {
    key: "patient_filter",
    name: "Patient",
  },
  {
    key: "file",
    name: "Link to Download ",
    displayCell: (cellValue) => (
      <DownloadFileLink
        href={cellValue}
        download
        onClick={()=>downloadClickAnalytic('Notes')}
      >
        Download
      </DownloadFileLink>
    ),
  },
];

const emailColumns = [
  { key: "id", name: "File ID" },
  { key: "status", name: "Status" },
  {
    key: "user",
    name: "User",
  },
  {
    key: "created",
    name: "Created Date",
  },
  {
    key:'status_filter',
    name:'Email Status'
  },
  {
    key: "file",
    name: "Link to Download ",
    displayCell: (cellValue) => (
      <DownloadFileLink
        href={cellValue}
        download
        onClick={()=>downloadClickAnalytic("Emails")}
      >
        Download
      </DownloadFileLink>
    ),
  },
];


const faxColumns = [
  { key: "id", name: "File ID" },
  { key: "status", name: "Status" },
  {
    key: "user",
    name: "User",
  },
  {
    key: "created",
    name: "Created Date",
  },
  {
    key:'status_filter',
    name:'Fax Status'
  },
  {
    key: "file",
    name: "Link to Download ",
    displayCell: (cellValue) => (
      <DownloadFileLink
        href={cellValue}
        download
        onClick={()=>downloadClickAnalytic("Faxes")}
      >
        Download
      </DownloadFileLink>
    ),
  },
];


const ExportHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  const [reportsPageNumber, setReportsPageNumber] = useState(1);
  const [notesPageNumber, setNotesPageNumber] = useState(1);
  const [emailsPageNumber, setEmailsPageNumber] = useState(1);
  const [faxesPageNumber, setFaxesPageNumber] = useState(1);
  const [scroll, setScroll] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const itemsPortionSize = 10;
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);
  const [displayReportsItemsCount, setDisplayReportsItemsCount] =
    useState(itemsPortionSize);
  const [displayNotesItemsCount, setDisplayNotesItemsCount] =
    useState(itemsPortionSize);
    const [displayEmailsItemsCount, setDisplayEmailItemsCount] =
    useState(itemsPortionSize);
    
    const [displayFaxesItemsCount, setDisplayFaxesItemsCount] =
    useState(itemsPortionSize);

  const [isActiveFirst, setIsActiveFirst] = useState(
    history.location.pathname.split("/")[3] === "exports"
  );
  const [activeTab, setActiveTab] = useState("reports");
  const exportHistory = useSelector((state) => state.radAdmin.exportHistory);
  const exportMessage = useSelector((state) => state.radAdmin.exportMessages);
  const totalItems = useSelector(
    (state) => state.radAdmin.exportMessages.totalItems
  );
  const totalReportsItems = useSelector(
    (state) => state.radAdmin.exportHistory.totalItems
  );

  const exportNotes = useSelector((state) => state.radAdmin.exportNotes);
  const totalNotesItems = useSelector(
    (state) => state.radAdmin.exportNotes.totalItems
  );

  
  const exportEmails = useSelector((state) => state.radAdmin.exportEmails);
  const exportFaxes = useSelector((state) => state.radAdmin.exportFaxes);

  const totalEmailsItems = useSelector(
    (state) => state.radAdmin.exportEmails.totalItems
  );

  const totalFaxesItems = useSelector(
    (state) => state.radAdmin.exportFaxes.totalItems
  );

  useEffect(() => {
    dispatch(getRadminExports(1));
    dispatch(getExportMessages(1));
    dispatch(getExportNotes(1));
    dispatch(getExportEmails(1))
    dispatch(getExportFaxes(1))
  }, []);

  const onLoadMore = () => {
    setIsLoadingMore(true);
    if (totalItems >= 10) {
      window.analytics.track("Export-History-Messages-Load-More-Click")
      dispatch(getExportMessages(pageNumber + 1));
      setPageNumber((prevNumber) => prevNumber + 1);
      setDisplayItemsCount(displayItemsCount + itemsPortionSize);
      return;
    }
  };

  const onLoadMoreNotes = () => {
    setIsLoadingMore(true);
    if (totalNotesItems >= 10) {
      window.analytics.track("Export-History-Notes-Load-More-Click")
      dispatch(getExportNotes(notesPageNumber + 1));
      setNotesPageNumber((prevNumber) => prevNumber + 1);
      setDisplayNotesItemsCount(displayNotesItemsCount + itemsPortionSize);
      return;
    }
  };

  const onLoadMoreReports = () => {
    setIsLoadingMore(true);
    if (totalReportsItems >= 10) {
      window.analytics.track("Export-History-Reports-Load-More-Click")
      dispatch(getRadminExports(reportsPageNumber + 1));
      setReportsPageNumber((prevNumber) => prevNumber + 1);
      setDisplayReportsItemsCount(displayReportsItemsCount + itemsPortionSize);
    }
  };

  const onLoadMoreEmails = () => {
    setIsLoadingMore(true);
    if (totalEmailsItems >= 10) {
      window.analytics.track("Export-History-Emails-Load-More-Click")
      dispatch(getExportEmails(emailsPageNumber + 1));
      setEmailsPageNumber((prevNumber) => prevNumber + 1);
      setDisplayNotesItemsCount(displayEmailsItemsCount + itemsPortionSize);
      return;
    }
  };

  const onLoadMoreFaxes = () => {
    setIsLoadingMore(true);
    if (totalFaxesItems >= 10) {
      window.analytics.track("Export-History-Faxes-Load-More-Click")
      dispatch(getExportFaxes(faxesPageNumber + 1));
      setFaxesPageNumber((prevNumber) => prevNumber + 1);
      setDisplayFaxesItemsCount(displayFaxesItemsCount + itemsPortionSize);
      return;
    }
  };

  return (
    <Box p={30}>
      <Headercontainer>
        <PageTitle>Export History</PageTitle>
        <SwitchContainer>
          <SwitchButton
            active={activeTab === "reports"}
            onClick={() => {
              window.analytics.track("Export-History-Reports-Click")
              setActiveTab("reports");
            }}
          >
            Reports
          </SwitchButton>
          <SwitchButton
            active={activeTab === "messages"}
            onClick={() => {
              window.analytics.track("Export-History-Messages-Click")
              setActiveTab("messages");
            }}
          >
            Messages
          </SwitchButton>
          <SwitchButton
            active={activeTab === "notes"}
            onClick={() => {
              window.analytics.track("Export-History-Notes-Click")
              setActiveTab("notes");
            }}
          >
            Notes
          </SwitchButton>
          <SwitchButton
            active={activeTab === "emails"}
            onClick={() => {
              window.analytics.track("Export-History-Email-Click")
              setActiveTab("emails");
            }}
          >
            Emails
          </SwitchButton>
          <SwitchButton
            active={activeTab === "faxes"}
            onClick={() => {
              window.analytics.track("Export-History-Faxes-Click")
              setActiveTab("faxes");
            }}
          >
            Faxes
          </SwitchButton>
          
        </SwitchContainer>
      </Headercontainer>
      <Card>
        {activeTab === "reports" ? (
          <>
            <Table
              rows={exportHistory.data}
              columns={columns}
              initialPageNumber={exportHistory.pageNumber}
              totalItems={exportHistory.totalItems}
              isLoadingRows={exportHistory.isLoading && reportsPageNumber < 2}
              getRowsAction={(pageNumber) => getRadminExports(pageNumber)}
              noDataRender={() => (
                <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
                  Sorry, there are no results
                </EmptyState>
              )}
            />
            {exportHistory?.data && exportHistory?.data.length !== 0 && (
              <LoadMoreButton
                title="Load more"
                handleClick={onLoadMoreReports}
                isDisabled={totalReportsItems === exportHistory.data.length}
                isLoading={exportHistory.isLoading}
                style={{
                  backgroundColor: "#0c2945",
                  fontWeight: 600,
                }}
              />
            )}
          </>
        ) : activeTab === "messages" ? (
          <>
            <Table
              rows={exportMessage.data}
              columns={messageColumns}
              initialPageNumber={exportMessage.pageNumber}
              totalItems={exportMessage.totalItems}
              isLoadingRows={exportMessage.isLoading && pageNumber < 2}
              noDataRender={() => (
                <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
                  Sorry, there are no results
                </EmptyState>
              )}
            />
            {exportMessage?.data && exportMessage?.data.length !== 0 && (
              <LoadMoreButton
                title="Load more"
                handleClick={onLoadMore}
                isDisabled={totalItems === exportMessage.data.length}
                isLoading={exportMessage.isLoading}
                style={{
                  backgroundColor: "#0c2945",
                  fontWeight: 600,
                }}
              />
            )}
          </>
        ) :activeTab === "notes"? (
          <>
            <Table
              rows={exportNotes.data}
              columns={notesColumns}
              initialPageNumber={exportNotes.pageNumber}
              totalItems={exportNotes.totalItems}
              isLoadingRows={exportNotes.isLoading && notesPageNumber < 2}
              noDataRender={() => (
                <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
                  Sorry, there are no results
                </EmptyState>
              )}
            />
            {exportNotes?.data && exportNotes?.data.length !== 0 && (
              <LoadMoreButton
                title="Load more"
                handleClick={onLoadMoreNotes}
                isDisabled={totalNotesItems === exportNotes.data.length}
                isLoading={exportNotes.isLoading}
                style={{
                  backgroundColor: "#0c2945",
                  fontWeight: 600,
                }}
              />
            )}
          </>
        ):activeTab === 'emails'?
        <>
        <Table
          rows={exportEmails.data}
          columns={emailColumns}
          initialPageNumber={exportEmails.pageNumber}
          totalItems={exportEmails.totalItems}
          isLoadingRows={exportEmails.isLoading && emailsPageNumber < 2}
          noDataRender={() => (
            <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
              Sorry, there are no results
            </EmptyState>
          )}
        />
        {exportEmails?.data && exportEmails?.data.length !== 0 && (
          <LoadMoreButton
            title="Load more"
            handleClick={onLoadMoreEmails}
            isDisabled={totalEmailsItems === exportEmails.data.length}
            isLoading={exportEmails.isLoading}
            style={{
              backgroundColor: "#0c2945",
              fontWeight: 600,
            }}
          />
        )}
      </>
      :  <>
      <Table
        rows={exportFaxes.data}
        columns={faxColumns}
        initialPageNumber={exportFaxes.pageNumber}
        totalItems={exportFaxes.totalItems}
        isLoadingRows={exportFaxes.isLoading && faxesPageNumber < 2}
        noDataRender={() => (
          <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
            Sorry, there are no results
          </EmptyState>
        )}
      />
      {exportFaxes?.data && exportFaxes?.data.length !== 0 && (
        <LoadMoreButton
          title="Load more"
          handleClick={onLoadMoreFaxes}
          isDisabled={totalFaxesItems === exportFaxes.data.length}
          isLoading={exportFaxes.isLoading}
          style={{
            backgroundColor: "#0c2945",
            fontWeight: 600,
          }}
        />
      )}
    </>
        }
      </Card>
    </Box>
  );
};

export default ExportHistory;

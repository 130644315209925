import React, { useState, useEffect } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import TextField from "@material-ui/core/TextField";
import loadMoreIcon from "../../../assets/load-more.svg";
import { Spinner } from "react-bootstrap";
import { isArray, isEmpty, isNull } from "lodash";
import styled from "styled-components";
import { makeStyles, styled as materialStyle } from "@material-ui/core/styles";
import { ReactComponent as ArrowDropDownIcon } from "../../../assets/chevron_down.svg";
import { ReactComponent as ArrowDropUpIcon } from "../../../assets/chevron_up.svg";
import { Dialog, Typography, Grid, TextareaAutosize } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import { ReactComponent as DeleteIcon } from "../../../assets/FollowUpDeleteIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import {
  createOutgoingFax,
  createFaxTemplate,
  updateFaxTemplate,
  showAlert,
  getFaxTemplates,
  deleteFaxTemplate,
  setFaxTemplateIdToPreview,
  setFaxTemplateToPreview,
} from "../../../actions/radminUploads";
import Editor from "./Editor";
import { ReactComponent as CloseIcon } from "../../../assets/cancel-outline.svg";
import Flex from "../../../components/_styles/Flex";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  datePicker: {
    paddingLeft: 36,
  },
  dialogContent: {
    minWidth: 577,
    maxWidth: 800,
    maxHeight: 600,
    minHeight: 188,
  },
  leftTabHead: {
    paddingBottom: 10,
  },
  tabLabel: {
    cursor: "pointer",
    color: "#9FA2B4",
    textAlign: "center",
  },
  rightTabHead: {
    textAlign: "right",
    paddingBottom: 10,
  },
  tabBorderBottom: {
    borderBottom: "3px solid #4dabf5",
  },
  formControlDropdownMessage: {
    width: "95%",
  },
  formControlDropdownTemplate: {
    width: "95%",
    marginLeft: "5%",
  },
  selectInput: {
    padding: 10,
    fontSize: 16,
    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
  chooseMessageTypeSec: {
    display: "flex",
  },
  labelColor: {
    color: "#0C2945",
  },
  textField: {
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
      padding: "10.5px 14px",
    },
  },
  centerSec: {
    height: "auto",
    maxHeight: 410,
    margin: "0 auto",
  },
  smsFromSec: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  smsToSec: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const SmsContent = materialStyle(Typography)({
  color: "#3F4857",
  fontSize: 14,
  fontWeight: 600,
  padding: "10px 0",
});

const PersionalizedText = materialStyle(Typography)({
  color: "black",
  fontSize: 16,
  fontWeight: 400,
  padding: "8px 0",
  width: "100%",
  paddingLeft: 10,
});

const AddButton = styled.button`
  color: white;
  background-color: #00aeef;
  border: none;
  border-radius: 4px;
  padding: 0 12px;
  font-size: 15px;
  font-weight: 600;
  height: 38px;
  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    background-color: #06739D;
    border: none;
  }

  &:hover span {
    transform: scale(1.1);
  }
`;
const InputField = styled.input`
  height: 38px;
  width: 255px;
  font-size: 16px;
  border: 1px solid #d2d3d7;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
`;


const NewDateLabel = styled.p`
font-weight: 600;
font-size:14px;
color:#00AEEF;
cursor:pointer;
text-align:center;
margin-bottom:0px;
`

export const DatePickerButton = styled(Flex)`
  padding: 13px;
  align-items: center;
  background:#00AEEF; 
  height:38px;
  border: none;
  width:210px;
  border-radius: 4px;
  justify-content:center;
  &:hover {
     cursor: pointer;
    background-color: ${(props) => props.hoverBg || '#0088CC'};
    border: none;
  }
`

export const DateContainer = styled(Flex)`
  font-size:14px;
  color:#ffffff;
  font-weight:600;
  span {
    transition: transform 0.3s ease-in-out;
  }

  &:hover span {
    font-weight: bold;
    transform: scale(1.1);
  }
`

const CtaLabel = styled.h4`
color:#0C2945;
font-size:18px;
font-weight: 500;
font-family:Roboto Condensed;
margin-bottom:16px;
`

const Button = styled.button`
  width: 172px;
  height: 38px;
  background-color: ${(props) => (props.bg ? props.bg : "#00AEEF")};
  border-radius: 4px;
  border: ${(props) => (props.border ? "2px solid #00AEEF" : 0)};
  font: bold 14px "Open Sans", sans-serif;
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#ffffff")};
  display: block;
  cursor: ${(props) => (props.disabled ? "" : "pointer")};
  opacity: ${(props) => (props.disabled ? "0.3" : "1")};

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out;
  }

   &:hover {
    background-color: ${(props) => props.hoverBg ? props.hoverBg : props.outlinedBr ? '#ffffff' : '#0088CC'};
    border: ${(props) => props.outlinedBr ? `2px solid ${props.outlinedBr}` : 'none'};
  }

  &:hover span {
    color: ${(props) => (props.outlinedBr ? props.outlinedBr : "")};
    font-weight: bold;
    transform: scale(1.1);
  }
`;

const OutlinedButton = styled.button`
  width: ${(props) => props.width || "120px"};
  height: ${(props) => props.height || "38px"};
  background: ${(props) => (props.disabled ? "#ffffff" : props.outlinedBr ? '#ffffff' : props.bg || "white")};
  color: ${(props) => (props.disabled ? "#00AEEF" : props.color || "#00AEEF")};
  border: ${(props) => (props.disabled ? "2px solid #00AEEF" : '2px solid #00AEEF')};
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: ${(props) => (props.templates && props.templates.length >= props.totalTemplates ? "not-allowed" : "pointer")};
  transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;

  &:hover {
    color: ${(props) => (props.disabled ? "#a0a0a0" : props.outlinedBr ? "#06739D" :"#ffffff")};
    transform: ${(props) => (props.disabled ? "none" : "scale(1.05)")};
    border: ${(props) => (props.disabled ? "2px solid #00AEEF" : `2px solid #06739D`)}
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Roboto Condensed", sans-serif;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  span p {
  display: flex;
    align-items: center;
    margin: auto;
  }

  &:hover span {
    transform: ${(props) => (props.disabled ? "none" : "scale(1.1)")};
    color: ${(props) => (props.disabled ? "#00AEEF" : props.outlinedBr ? "#06739D" :"#ffffff")};
  }
  img {
    transition: filter 0.3s ease-in-out;
  }
`;

export const NormalText = materialStyle(Typography)({
  fontSize: 14,
  color: "#3F4857",
  fontFamily: "Roboto Condensed",
  display: "inline",
});
export const PersionalizedTextContainer = materialStyle(Grid)({
  border: "1px solid #CBCCCF",
  borderRadius: "4px",
});
const PersonalizedFieldSection = materialStyle(Grid)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  gap: "21px",
  justifyContent: "flex-end",
  marginRight: "21px",
});

export const PersonalizedInnerContainer = materialStyle(Grid)({
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: 38,
});
const InsertPersonalizedSection = materialStyle(Grid)({
  backgroundColor: "#F5F5F5",
  height: "200px",
  overflowY: "auto",
  padding: "10px 0px",
});
const PersonalizedRow = materialStyle(Grid)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
});
const PeronalizedText = materialStyle(Typography)({
  fontSize: 14,
  fontWeight: 400,
  margin: 0,
  marginLeft: 10,
  color: "#8F8F8F",
});
export const ParagraphContainer = materialStyle(Grid)({
  padding: "5px 10px",
  cursor: "auto",
});
export const SmsInfoContainer = materialStyle(Grid)({
  padding: "0px 10px",
  display: "flex",
});

const BottomHeading = materialStyle(Typography)({
  fontSize: 19,
  padding: "8px 0 ",
  fontWeight: 700,
  color: "#252733",
  fontFamily: "Roboto Condensed",
});
const TemplateRowContainer = materialStyle(Grid)({
  display: "flex",
  alignItems: "center",
  margin: "0 auto",
  justifyContent: "space-between",
  padding: "10px",
});
const TemplateTitle = materialStyle(Typography)({
  fontSize: 16,
  color: "#000000",
});
const ButtonContainer = materialStyle(Grid)({
  cursor: "pointer",
  height: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "6px 6px",
  color: (props) =>props.color ? props.color : "#ffffff",
  backgroundColor: (props) => props.isHover
    ? props.bg
      ? props.bg
      : "#00AEEF"
    : "transparent",
  width: (props) => props.width,
  borderRadius: 3,
  border: (props) => props.border ? "2px solid #00AEEF" : 0,
  "p": {
    display: (props) => props.isHover ?'inline-block' : '',
    transition: (props) => props.isHover ? "transform 0.3s ease-in-out" : "",
  },

  "&:hover": {
    backgroundColor: (props) =>props.hoverBg
      ? props.hoverBg
      : props.outlinedBr
      ? "#ffffff"
      : "#0088CC",
    border: (props) => props.isHover
      ? props.hoverBg
        ? props.outlinedBr
          ? `2px solid ${props.outlinedBr}`
          : "none"
        : "none"
      : "2px solid #00AEEF",
  },
  "&:hover p": {
    fontWeight: (props) => props.isHover ? "bold" : '',
    transform: (props) => props.isHover ? "scale(1.1)" : '',
  }
});

const BorderWraper = materialStyle(Grid)({
  border: "1px solid #BFBFBF",
  background: "#F6F6F6",
  marginBottom: "10px",
  borderRadius: "5px",
});
const DatePickerContainer = materialStyle(Grid)({
  width: "100%",
  "& .react-datepicker": {
    paddingLeft: 36,
  },
});
const BottomContainer = materialStyle(Grid)({
  margin: "0 auto",
});
const FlexContainer = materialStyle(Grid)({
  display: "flex",
});
export const DateLabel = materialStyle(Typography)({
  color: "#AAAEBD",
  fontSize: 12,
});

const Text = materialStyle(Typography)({
  color: (props) => props.textcolor,
  fontSize: 14,
  fontWeight: 600,
  fontFamily: "Roboto Condensed",
});

const SendContainer = materialStyle(Grid)({
  display: 'flex',
  gap:'30px',
  marginTop:'auto'
});

const PhysicianInfoCard = styled.div`
border:1px solid #BFBFBF;
border-radius:4px;
background:#F6F6F6;
padding:12px 10px;
width:324px;
`

const PhysicianInfoCardLabel = styled.p`
color:#252733;
font-weight:600;
font-size:14px;
width:138px;
margin-bottom:0;
`

const PhysicianInfoCardValue = styled.div`
color:#252733;
font-weight:400;
font-size:14px;
margin-bottom:0;
`
const PhysicianInfoCardRow = styled.div`
display:flex;
margin-bottom:3px;
`

const DeliverContainer = styled.div`
display:flex;
justify-Content:space-between;
width:100%;
margin-Bottom:${props=>props.margin};
`

const CenterSecContainer = materialStyle(Grid)({
  height: 298,
});



const PreviewButtonContainer = materialStyle(Grid)({
  display: "flex",
  justifyContent: "flex-end",
  // marginTop: "5%",
  marginBottom: "28px",
  color: '#00AEEF',
  'p': {
    
    transition: 'transform 0.3s ease-in-out',
  },

  '&:hover p': {
    color: '#06739D',
    fontWeight: 'bold',
    transform: 'scale(1.1)'
  }
});

const CustomButton = materialStyle(Grid)({
  backgroundColor: (prop) => prop.bg,
  display: "flex",
  fontFamily: "Open Sans",
  width: (prop) => (prop.width ? prop.width : 135),
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  fontSize: 14,
  fontWeight: 600,
  borderRadius: 4,
  height: "38px",
  padding: "0 10px",
  cursor: (props) => (props.disabled ? "default" : "pointer"),

  'span': {
    display: 'inline-block',
    transition: 'transform 0.3s ease-in-out',
  },

  '&:hover': {
    backgroundColor: (props) => props.hoverBg || '#0088CC',
    border: 'none'
  },
  
  '&:hover span': {
    fontWeight: 'bold',
    transform: 'scale(1.1)',
    color:'#ffffff !important'
  }
});
const AddActionBtn = materialStyle(Grid)({
  backgroundColor: "#00AEEF",
  display: "flex",
  fontFamily: "Roboto Condensed",
  cursor: "pointer",
  width: 200,
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  fontSize: 13,
  fontWeight: 600,
  borderRadius: 4,
  height: "55px",
  padding: "0 10px",
  marginLeft: "45%",
});
export const CreateTemplateContainer = materialStyle(Grid)({
  maxHeight: "290px",
});

const TemplateBtnContainer = materialStyle(Grid)({
  display: "flex",
  gap: "15px",
});

export default function FaxTemplateModal({
  openMessage,
  openMessageHandler,
  patientDetails,
  currentNode,
}) {
  const messageContentText = `This is placeholder text - Change ME!`;
  const classes = useStyles();
  const dispatch = useDispatch();
 
  const templateIdToPreview = useSelector(
    (state) => state.radAdmin.faxTemplates.templateIdToPreview
  );
  const templateToPreview = useSelector(
    (state) => state.radAdmin.faxTemplates.templateToPreview
  );
 
  const totalTemplates = useSelector(
    (state) => state.radAdmin.faxTemplates?.totalItems
  );

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "add_follow-ups")?.is_authorized
  const updatePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "change_follow-ups")?.is_authorized


  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [selectedTab, setTab] = useState("1");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [clickedButton, setClickedButton] = useState("");
  const [sendTo, setSendTo] = useState("-");
  const [templateTitle, setTemplateTitle] = useState(currentNode?.title || "");
  const [templateContent, setTemplateContent] = useState(
    currentNode?.fax_content || ""
  );


  const [templatePageNo, setTemplatePageNo] = useState(1);
  const [isClickedPersionalized, setIsClickedPersionalized] = useState(false);
  const [isSaveTemplateClicked, setIsSaveTemplateClicked] = useState(false);
  const [defaultValue, setDefaultValue] = useState({
    patientMRN: "",
    recommendationAnatomy: "",
    recommendationModality: "",
    recommendationRangeEnd: "",
    recommendationRangeStart: "",
    referringPhysicianFirstName: "",
    referringPhysicianLastName: "",
    patientFirstName: "",
    patientLastName: "",
    initialStudyModal: "",
    initialLocationAddress: "",
    examDate: "",
  });
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(getFaxTemplates(templatePageNo));
  }, []);

  useEffect(() => {
    if (!isNull(selectedTemplate)) {
      setTemplateTitle(selectedTemplate.title);
      setTemplateContent(selectedTemplate.fax_content);
      setDisableSaveButton(false);
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (templateToPreview) {
      setTemplateContent(templateToPreview);
    }
  }, [templateToPreview]);
  useEffect(() => {
    if (patientDetails?.patient?.phone_number) {
      setSendTo(patientDetails.patient.phone_number);
    }
  }, [patientDetails]);

  const onChange = (dates) => {
    if (isArray(dates)) {
      const [start] = dates;
      setDate(start);
      return;
    }
    setTime(dates);
  };

  const createTemplateHandler = () => {
    window.analytics.track("Save-As-Template-Button-Click");
    if (isEmpty(templateTitle)) {
      alert("Please enter title for template");
      return;
    }
    if (isEmpty(templateContent)) {
      alert("Please enter content for template");
      return;
    }
    setIsSaveTemplateClicked(true);
    let dataForTemplate = {
      title: templateTitle,
      personalized_content: {},
      fax_content: templateContent,
    };

    if (defaultValue.patientMRN) {
      dataForTemplate.personalized_content.patient_mrn =
        defaultValue.patientMRN;
    }
    if (defaultValue.recommendationAnatomy) {
      dataForTemplate.personalized_content.recommendation_anatomy =
        defaultValue.recommendationAnatomy;
    }
    if (defaultValue.recommendationModality) {
      dataForTemplate.personalized_content.recommendation_modality =
        defaultValue.recommendationModality;
    }
    if (defaultValue.recommendationRangeEnd) {
      dataForTemplate.personalized_content.recommendation_range_end = defaultValue.recommendationRangeEnd
    }
    if (defaultValue.recommendationRangeStart) {
      dataForTemplate.personalized_content.recommendation_range_start = defaultValue.recommendationRangeStart
    }
    if (defaultValue.referringPhysicianFirstName) {
      dataForTemplate.personalized_content.referring_physician_first_name =
        defaultValue.referringPhysicianFirstName;
    }
    if (defaultValue.referringPhysicianLastName) {
      dataForTemplate.personalized_content.referring_physician_last_name =
        defaultValue.referringPhysicianLastName;
    }
    if (defaultValue.patientFirstName) {
      dataForTemplate.personalized_content.patient_first_name =
        defaultValue.patientFirstName;
    }
    if (defaultValue.patientLastName) {
      dataForTemplate.personalized_content.patient_last_name =
        defaultValue.patientLastName;
    }
    if (defaultValue.initialStudyModal) {
      dataForTemplate.personalized_content.initial_study_modality =
        defaultValue.initialStudyModal;
    }
    if (defaultValue.initialLocationAddress) {
      dataForTemplate.personalized_content.initial_exam_location_address =
        defaultValue.initialLocationAddress;
    }
    if (defaultValue.examDate) {
      dataForTemplate.personalized_content.exam_date = defaultValue.examDate
    }

    dispatch(createFaxTemplate(dataForTemplate)).then((data) => {
      setIsSaveTemplateClicked(false);
      if(data?.response){
        setSelectedTemplate(data?.response);
        dispatch(setFaxTemplateIdToPreview(data?.response?.id));
      }
    
    });
  };

  const updateTemplateHandler = () => {
    if (isSaveTemplateClicked) {
      return;
    }
    window.analytics.track("Edit-Template-Button-Click");

    setIsSaveTemplateClicked(true);
    let dataForTemplate = {
      fax_content: templateContent,
      personalized_content: {},
    };

    if (defaultValue.patientMRN) {
      dataForTemplate.personalized_content.patient_mrn =
        defaultValue.patientMRN;
    }
    if (defaultValue.recommendationAnatomy) {
      dataForTemplate.personalized_content.recommendation_anatomy =
        defaultValue.recommendationAnatomy;
    }
    if (defaultValue.recommendationModality) {
      dataForTemplate.personalized_content.recommendation_modality =
        defaultValue.recommendationModality;
    }
    if (defaultValue.recommendationRangeEnd) {
      dataForTemplate.personalized_content.recommendation_range_end = defaultValue.recommendationRangeEnd
    }
    if (defaultValue.recommendationRangeStart) {
      dataForTemplate.personalized_content.recommendation_range_start = defaultValue.recommendationRangeStart
    }
    if (defaultValue.referringPhysicianFirstName) {
      dataForTemplate.personalized_content.referring_physician_first_name =
        defaultValue.referringPhysicianFirstName;
    }
    if (defaultValue.referringPhysicianLastName) {
      dataForTemplate.personalized_content.referring_physician_last_name =
        defaultValue.referringPhysicianLastName;
    }
    if (defaultValue.patientFirstName) {
      dataForTemplate.personalized_content.patient_first_name =
        defaultValue.patientFirstName;
    }
    if (defaultValue.patientLastName) {
      dataForTemplate.personalized_content.patient_last_name =
        defaultValue.patientLastName;
    }
    if (defaultValue.initialStudyModal) {
      dataForTemplate.personalized_content.initial_study_modality =
        defaultValue.initialStudyModal;
    }
    if (defaultValue.initialLocationAddress) {
      dataForTemplate.personalized_content.initial_exam_location_address =
        defaultValue.initialLocationAddress;
    }
    if (defaultValue.examDate) {
      dataForTemplate.personalized_content.exam_date = defaultValue.examDate
    }

    dispatch(updateFaxTemplate(dataForTemplate, selectedTemplate.id)).then(
      () => {
        setIsSaveTemplateClicked(false);
      }
    );
  };
 

  const sendMessageHandler = (type) => {
    if (!selectedTemplate) {
      alert("Please select template");
      return;
    }

    const currentTime = moment().toISOString();
    let requiredDate = moment(date).format("YYYY-MM-DD");
    let requiredTime = moment(time).format("HH:mm:ss");
    const dateTime = moment(
      `${requiredDate} ${requiredTime}`,
      "YYYY-MM-DD HH:mm:ss"
    ).toISOString();

    if (type === "add_future_action") {
      window.analytics.track("Add-Future-Action-Button-Click");
      setClickedButton("add_future_action");
      let scheduledDate = new Date(dateTime);
      let scheduledTime = new Date(dateTime).getTime();
      let today = new Date();
      let incrementedTime = today.setMinutes(today.getMinutes() + 30); // adding 30 min into current time
      let todayTime = new Date(incrementedTime).getTime();
      if (time === "") {
        alert("Please select time for this action");
        return;
      }
      if (scheduledDate >= today && scheduledTime >= todayTime) {
        setIsLoading(true);
        dispatch(
          createOutgoingFax({
            referringPhysicianId:patientDetails.referring_physician.id,
            coverPageId:selectedTemplate.template_id,
            sendAt: dateTime,
          })
        ).then((res) => {
          if (res) {
            openMessageHandler(false);
            dispatch(
              showAlert(
                "success",
                "Success",
                `Successfully scheduled to ${patientDetails?.referring_physician?.first_name} ${patientDetails?.referring_physician?.last_name}!`
              )
            );
            setTemplateTitle("");
            setSendTo("-");
            setSelectedTemplate(null);
            setTime("");
          }
          setIsLoading(false);
        });
      } else {
        alert("Invalid time for this action");
      }
      return;
    }
    window.analytics.track("Follow-Up-List-Fax-Send-Now-Button-Click");
    setClickedButton("send_now");
    setIsLoading(true);
    dispatch(
      createOutgoingFax({
            referringPhysicianId:patientDetails?.referring_physician?.id,
            coverPageId:selectedTemplate?.template_id,
            sendAt: time ? dateTime : currentTime,
      })
    ).then((res) => {
      if (res) {
        openMessageHandler(false);
        dispatch(
          showAlert(
            "success",
            "Sent",
            `Successfully sent to ${patientDetails?.referring_physician?.first_name} ${patientDetails?.referring_physician?.last_name}!`
          )
        );
        setTemplateTitle("");
        setSendTo("-");
        setSelectedTemplate(null);
        setTime("");
      }
      setIsLoading(false);

    });
  };

  const addActionHandler = () => {
    alert("Action not available for now");
    if (sendTo === "-") {
      alert("Please select recipient");
      return;
    }
    setClickedButton("add_action");
  };

  const updateTemplatePageNum = (value) => {
    setTemplatePageNo(value);
  };

  const onCloseDialogHandler = () => {
    openMessageHandler(false);
    setTemplateContent("");
    setTemplateTitle("");
    setTab("1");
    setSendTo("-");
    setSelectedTemplate(null);
    setTime("");
    setIsClickedPersionalized(false);
    setDefaultValue({
      ...defaultValue,
      patientMRN: "",
      recommendationAnatomy: "",
      recommendationModality: "",
      recommendationRangeEnd: "",
      recommendationRangeStart: "",
      referringPhysicianFirstName: "",
      referringPhysicianLastName: "",
      patientFirstName: "",
      patientLastName: "",
      initialStudyModal: "",
      initialLocationAddress: "",
      examDate: "",
    });
    dispatch(setFaxTemplateIdToPreview(null));
    dispatch(setFaxTemplateToPreview(null));
  };

  const personalizedClickHandler = () => {
    setIsClickedPersionalized(!isClickedPersionalized);
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    setDefaultValue({ ...defaultValue, [name]: value });
  };

  const checkforTemplatedContent = (value, key) => {
    let content = templateContent;
    let newValue = `${key} | default("${value}")`;
    let replacedValue = content.replace(key, newValue);
    let valueToSearch = `${key} | default`;
    let requiredIndex = content.indexOf(valueToSearch);
    if (replacedValue === content) {
      let concatedValue = content + " " + `{{${newValue}}}`;
      setTemplateContent(concatedValue);
    } else if (requiredIndex !== -1) {
      let splited = content.split(`{{${key}`);
      let requiredValue = splited[splited.length - 1].split('"')[1];
      let replacedVal = content.replace(requiredValue, value);
      setTemplateContent(replacedVal);
    } else {
      setTemplateContent(replacedValue);
    }
    setIsClickedPersionalized(false);
  };

  const addDefaultHandler = (from) => {
    if (from === "row1") {
      checkforTemplatedContent(defaultValue.patientMRN, "patient_mrn");
    } else if (from === "row2") {
      checkforTemplatedContent(
        defaultValue.recommendationAnatomy,
        "recommendation_anatomy"
      );
    } else if (from === "row3") {
      checkforTemplatedContent(
        defaultValue.recommendationModality,
        "recommendation_modality"
      );
    } else if (from === "row4") {
      checkforTemplatedContent(
        defaultValue.recommendationRangeEnd,
        "recommendation_range_end"
      );
    } else if (from === "row5") {
      checkforTemplatedContent(
        defaultValue.recommendationRangeStart,
        "recommendation_range_start"
      );
    } else if (from === "row6") {
      checkforTemplatedContent(
        defaultValue.referringPhysicianFirstName,
        "referring_physician_first_name"
      );
    } else if (from === "row7") {
      checkforTemplatedContent(
        defaultValue.referringPhysicianLastName,
        "referring_physician_last_name"
      );
    } else if (from === "row8") {
      checkforTemplatedContent(
        defaultValue.patientFirstName,
        "patient_first_name"
      );
    } else if (from === "row9") {
      checkforTemplatedContent(
        defaultValue.patientLastName,
        "patient_last_name"
      );
    } else if (from === "row10") {
      checkforTemplatedContent(
        defaultValue.initialStudyModal,
        "initial_study_modality"
      );
    } else if (from === "row11") {
      checkforTemplatedContent(
        defaultValue.initialLocationAddress,
        "initial_exam_location_address"
      );
    } else if (from === "row12") {
      checkforTemplatedContent(defaultValue.examDate, "exam_date");
    }
  };

  return (
    <div>
      <Dialog
        open={openMessage}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        // style={{ height: 800 }}
        style={{
          backdropFilter: "blur(5px)",
        }}
        onClose={() => onCloseDialogHandler()}
      >
        <DialogContent style={{ width: selectedTab==='3' ?'820px': selectedTab ==='2'? '597px' : 'initial', height: selectedTab ==='2'? '547px':'initial', paddingTop:'30px' }} className={classes.dialogContent}>
          {selectedTab === "2" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "26px",
              }}
            >
              <h2
                style={{
                  color: "#0C2945",
                  fontSize: "28px",
                  fontWeight: 700,
                  fontFamily: "Roboto Condensed",
                  marginBottom: 0,
                }}
              >
                Saved Templates
              </h2>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => openMessageHandler(false)}
              />
            </div>
          )}
          {selectedTab === "3" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h2
                style={{
                  color: "#0C2945",
                  fontSize: "28px",
                  fontWeight: 700,
                  fontFamily: "Roboto Condensed",
                  marginBottom: 0,
                }}
              >
                {selectedTemplate ? "Send Message" : "Create New Template"}
              </h2>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => openMessageHandler(false)}
              />
            </div>
          )}

          {selectedTab === "3" && !selectedTemplate && (
            <>
              <h4
                style={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#6D6E6E",
                  marginTop: "35px",
                }}
              >
                TEMPLATE TITLE
              </h4>
              <TextField
                id="outlined-uncontrolled"
                variant="outlined"
                className={classes.textField}
                value={templateTitle}
                onChange={(e) => setTemplateTitle(e.target.value)}
                fullWidth={true}
                placeholder="Create title"
              />
            </>
          )}
          {selectedTab === "3" && (
            <>
                <>
                  <h4
                    style={{
                      fontFamily: "Roboto Condensed",
                      fontSize: "14px",
                      fontWeight: 700,
                      color: "#6D6E6E",
                      marginTop: "30px",
                    }}
                  >
                    PERSONALIZED CONTENT
                  </h4>
                  <CreateTemplate
                    isClickedPersionalized={isClickedPersionalized}
                    personalizedClickHandler={personalizedClickHandler}
                    defaultValue={defaultValue}
                    handleChange={handleChange}
                    addDefaultHandler={addDefaultHandler}
                  />
                </>
              

              <h4
                style={{
                  fontFamily: "Roboto Condensed",
                  fontSize: "14px",
                  fontWeight: 700,
                  color: "#6D6E6E",
                  marginTop: "30px",
                }}
              >
                FAX CONTENT
              </h4>

              <Editor
                templateContent={templateContent}
                setTemplateContent={setTemplateContent}
                placeholder={"This is placeholder text - Change me!"}
              />
            </>
          )}
          {selectedTab === "1" ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "47px",
                  alignItems: "center",
                }}
              >
                <h2
                  style={{
                    fontSize: "28px",
                    fontWeight: 700,
                    color: "#0C2945",
                    marginBottom: "0px",
                    fontFamily: "Roboto Condensed",
                  }}
                >
                  New Fax
                </h2>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => openMessageHandler(false)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Button hoverBg={"#06739D"} onClick={() => setTab("2")}><span>Saved Templates</span></Button>
                <Button
                  bg="white"
                  color="#00AEEF"
                  border
                  brColor="#ffffff"
                  outlinedBr="#06739D"
                  onClick={() => {
                    if((permission && addPermission) || !permission){
                      setTab("3");
                    }else{
                      dispatch(
                        showAlert('danger', 'Error', `You don't have permission to create fax template`)
                      );
                    }                  
                  }}
                >
                  <span>Create New Template</span>
                </Button>
              </div>
            </>
          ) : (
            <>
              {selectedTab === "2" && (
                <CenterSecContainer>
                  <Grid className={classes.centerSec}>
                    {selectedTab === "2" ? (
                      <>
                        <SaveTemplateSec
                          setSelectedTemplate={setSelectedTemplate}
                          setIsSecondTab={setTab}
                          totalTemplates={totalTemplates}
                          templatePageNo={templatePageNo}
                          setTemplatePageNo={updateTemplatePageNum}
                          setDefaultValue={setDefaultValue}
                        />
                      </>
                    ) : null}
                  </Grid>
                </CenterSecContainer>
              )}

              {selectedTab === "3" && (
                <BottomContainer>
                  <PreviewButtonContainer>
                    {selectedTemplate ? (
                      <p
                      onClick={()=>{
                        if((permission && updatePermission) || !permission){
                          updateTemplateHandler()
                        }else{
                          dispatch(
                            showAlert('danger', 'Error', `You don't have permission to update fax template`)
                          );
                        }                  
                      }}
                        style={{
                          fontWeight: 600,
                          fontSize: "14px",
                          cursor: isSaveTemplateClicked? "not-allowed" : "pointer",
                          marginTop: "5px",
                        }}
                      >
                        Save Changes to Template
                      </p>
                    ) : (
                      <CustomButton
                        bg="#00AEEF"
                        width="104px"
                        hoverBg={"#06739D"}
                        onClick={createTemplateHandler}
                        style={{marginTop:'30px'}}
                      >
                        { isSaveTemplateClicked ? (
                          <Spinner animation="border" role="status" size="sm" />
                        ) : (
                          <span>Save</span>
                        )}
                      </CustomButton>
                    )}
                  </PreviewButtonContainer>
                </BottomContainer>
              )}
            </>
          )}
             {
          selectedTemplate && selectedTab === '3' && <BottomContainer>
          {selectedTab === '3' && (
            <BottomHeading>When should this get delivered? </BottomHeading>
          )}
          <FlexContainer>
              <CallToActions
                addActionHandler={addActionHandler}
                clickedButton={clickedButton}
                isLoading={isLoading}
                selectedTab={selectedTab}
                sendMessageHandler={sendMessageHandler}
                disabled={disableSaveButton}
                date={date}
                time={time}
                setTime={setTime}
                setDate={setDate}
                onChange={onChange}
                patientDetails={patientDetails}
              />
          </FlexContainer>
          { date && time &&
          <div style={{display:'flex', gap:'30px',  marginBottom:'10px', marginTop:'7px'}}> 
            <div style={{width:'104px'}}/>
            <div style={{width:'210px'}}>
            <NewDateLabel onClick={()=>{
                      setDate('');
                      setTime('');
                    }}>Choose new date & time</NewDateLabel>
            </div>

          </div>
          } 
        </BottomContainer>
         } 
        </DialogContent>
      </Dialog>
    </div>
  );
}

const CallToActions = ({
  selectedTab,
  sendMessageHandler,
  isLoading,
  clickedButton,
  addActionHandler,
  disabled,
  date,
  time,
  setDate,
  setTime,
  onChange,
  patientDetails
}) => {

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "add_follow-ups")?.is_authorized
  const dispatch = useDispatch()

    return (
    <>
      {selectedTab === '3' ? (
        <DeliverContainer margin={date && time? '0px':'30px'}>
        <SendContainer>
          <div>
           <CtaLabel>Now</CtaLabel>
          <CustomButton width="104px" bg="#00AEEF" hoverBg={"#06739D"} color="#ffffff" onClick={()=>{
              if((permission && addPermission) || !permission){   
                if(!isLoading){
                  sendMessageHandler()
                  }
              }else{
                dispatch(
                  showAlert('danger', 'Error', `You don't have permission to create send fax`)
                );
              }   
          
          }
          }>
            {isLoading && clickedButton === 'send_now' ? (
              <Spinner animation="border" role="status" size="sm" />
            ) : (
              <span>Send Now</span>
            )}
          </CustomButton>

          </div>
          <div>
           <CtaLabel>Later</CtaLabel>
           <div className="datePick">
                    <DatePickerContainer  onClick={()=> {
                       if((permission && addPermission) || !permission){
                        if(date && time && !isLoading){
                          sendMessageHandler('add_future_action')
                        }
                      }else{
                        dispatch(
                          showAlert('danger', 'Error', `You don't have permission to create send fax`)
                        );
                      }   
                    
                    }
                    }>
                      <DatePicker
                      disabled={ (date && time) || isLoading}
                        onChange={onChange}
                        dateFormat="MM/dd/yyyy"
                        customInput={
                          <DatePickerInput date={date} time={time} isLoading={isLoading}  clickedButton={clickedButton}  />
                        }
                        style={{ width: 300 }}
                        timeFormat="HH:mm"
                        timeIntervals={30}
                        showTimeSelect
                        selectsRange
                      />
                    </DatePickerContainer>
                  </div>
          </div>
        
        </SendContainer>
        <PhysicianInfoCard>
          <PhysicianInfoCardRow>
          <PhysicianInfoCardLabel>Referring Physician: </PhysicianInfoCardLabel>
          <PhysicianInfoCardValue>{patientDetails?.referring_physician?.first_name +' ' + patientDetails?.referring_physician?.last_name}</PhysicianInfoCardValue>
          </PhysicianInfoCardRow>
          <PhysicianInfoCardRow >
          <PhysicianInfoCardLabel>Fax Number:</PhysicianInfoCardLabel>
          <PhysicianInfoCardValue>{patientDetails?.referring_physician?.fax}</PhysicianInfoCardValue>
          </PhysicianInfoCardRow>
       
       <PhysicianInfoCardRow >
       <PhysicianInfoCardLabel>NPI:</PhysicianInfoCardLabel>
          <PhysicianInfoCardValue>{patientDetails?.referring_physician?.unique_id}</PhysicianInfoCardValue>
       </PhysicianInfoCardRow>
         
        </PhysicianInfoCard>
        </DeliverContainer>
      ) : (
        <SendContainer>
          {selectedTab === '1' && (
            <AddActionBtn onClick={() => addActionHandler()}>
              {isLoading && clickedButton === 'add_action' ? (
                <Spinner animation="border" role="status" />
              ) : (
                'Add Action'
              )}
            </AddActionBtn>
          )}
        </SendContainer>
      )}
    </>
  );
};

const SaveTemplateSec = ({
  setSelectedTemplate,
  setIsSecondTab,
  totalTemplates,
  templatePageNo,
  setTemplatePageNo,
  setDefaultValue
}) => {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.radAdmin.faxTemplates.data);
  const isLoading = useSelector(
    (state) => state.radAdmin.faxTemplates.isLoading
  );

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const deletePermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "delete_follow-ups")?.is_authorized



  const onLoadMore = () => {
    dispatch(getFaxTemplates(templatePageNo + 1));
    setTemplatePageNo(templatePageNo + 1);
  };

  const deleteTemplateHandle = (id) => {
    dispatch(deleteFaxTemplate(id, templatePageNo));
    window.analytics.track("Delete-Template-Button-Click");
  };

  const onSelectTemplate = (template) => {
    setSelectedTemplate(template);
    setDefaultValue({
      patientMRN: template?.personalized_content?.patient_mrn || '',
      recommendationAnatomy: template?.personalized_content?.recommendation_anatomy || '',
      recommendationModality:template?.personalized_content?.recommendation_modality || '',
      recommendationRangeEnd: template?.personalized_content?.recommendation_range_end || '',
      recommendationRangeStart: template?.personalized_content?.recommendation_range_start || '',
      referringPhysicianFirstName: template?.personalized_content?.referring_physician_first_name || '',
      referringPhysicianLastName: template?.personalized_content?.referring_physician_last_name || '',
      patientFirstName: template?.personalized_content?.patient_first_name || '',
      patientLastName: template?.personalized_content?.patient_last_name  || '',
      initialStudyModal: template?.personalized_content?.initial_study_modality || '',
      initialLocationAddress: template?.personalized_content?.initial_exam_location_address || '',
      examDate: template?.personalized_content?.exam_date || '',
    })
    setIsSecondTab("3");
    dispatch(setFaxTemplateIdToPreview(template.id));
    window.analytics.track("Use-Template-Button-Click");
  };
  return (
    <>
      {templates &&
        templates.map((t, i) => {
          return (
            <BorderWraper key={i}>
              <TemplateRowContainer>
                <Grid style={{ width: "70%" }}>
                  <TemplateTitle>{t.title}</TemplateTitle>
                </Grid>
                <TemplateBtnContainer>
                  <TemplateButton
                                      hoverBg={"#06739D"}
                                      color={"#ffffff"}
                                      isHover={true}
                                      title='Select'
                                      clickHandler={() => onSelectTemplate(t)}
                                      style={{ width: "82px" }}
                                      textStyles={{ color: "white" }}
                  ></TemplateButton>
                  <DeleteIcon
                    style={{
                      color: "rgb(241, 43, 44)",
                      fontSize: 21,
                      cursor: "pointer",
                    }}
                    onClick={() =>{
                      if((permission && deletePermission) || !permission){
                        deleteTemplateHandle(t.id)
                      }else{
                        dispatch(
                          showAlert('danger', 'Error', `You don't have permission to delete fax template`)
                        );
                      }                          
                    }}
                  />
                </TemplateBtnContainer>
              </TemplateRowContainer>
            </BorderWraper>
          );
        })}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <OutlinedButton
          disabled={templates && templates.length >= totalTemplates}
          onClick={onLoadMore}
          outlinedBr={'#00AEEF'}
          templates
          totalTemplates
        >
          <span
          >
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <>
                Load More
                <img src={loadMoreIcon} />
              </>
            )}
          </span>
        </OutlinedButton>
      </div>
    </>
  );
};

const CreateTemplate = ({
  isClickedPersionalized,
  personalizedClickHandler,
  defaultValue,
  handleChange,
  addDefaultHandler,
}) => {

  return (
    <>
      <CreateTemplateContainer>
        <PersionalizedTextContainer>
          <PersonalizedInnerContainer
            isClicked={isClickedPersionalized}
            onClick={personalizedClickHandler}
          >
            <PeronalizedText>Select...</PeronalizedText>
            {isClickedPersionalized ? (
              <ArrowDropUpIcon
                style={{ cursor: "pointer", marginRight: 10 }}
              />
            ) : (
              <ArrowDropDownIcon
                style={{ cursor: "pointer", marginRight: 10 }}
              />
            )}
          </PersonalizedInnerContainer>
        </PersionalizedTextContainer>

        {isClickedPersionalized ? (
          <InsertPersonalizedSection>
            <PersonalizedRow noBorder>
              <BottomContainer>
                <PersionalizedText>{"{{patient_mrn}}"}</PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="patientMRN"
                  value={defaultValue.patientMRN}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.patientMRN)
                      ? alert("Value required!")
                      : addDefaultHandler("row1")
                  }
                >
                 <span>Add</span> 
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{recommendation_anatomy}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="recommendationAnatomy"
                  value={defaultValue.recommendationAnatomy}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.recommendationAnatomy)
                      ? alert("Value required!")
                      : addDefaultHandler("row2")
                  }
                >
                  <span>Add</span> 
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{recommendation_modality}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="recommendationModality"
                  value={defaultValue.recommendationModality}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.recommendationModality)
                      ? alert("Value required!")
                      : addDefaultHandler("row3")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>

            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{recommendation_range_end}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="recommendationRangeEnd"
                  value={defaultValue.recommendationRangeEnd}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.recommendationRangeEnd)
                      ? alert("Value required!")
                      : addDefaultHandler("row4")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>

            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{recommendation_range_start}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="recommendationRangeStart"
                  value={defaultValue.recommendationRangeStart}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.recommendationRangeStart)
                      ? alert("Value required!")
                      : addDefaultHandler("row5")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{referring_physician_first_name}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="referringPhysicianFirstName"
                  value={defaultValue.referringPhysicianFirstName}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.referringPhysicianFirstName)
                      ? alert("Value required!")
                      : addDefaultHandler("row6")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{referring_physician_last_name}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="referringPhysicianLastName"
                  value={defaultValue.referringPhysicianLastName}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.referringPhysicianLastName)
                      ? alert("Value required!")
                      : addDefaultHandler("row7")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{patient_first_name}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="patientFirstName"
                  value={defaultValue.patientFirstName}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.patientFirstName)
                      ? alert("Value required!")
                      : addDefaultHandler("row8")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>{"{{patient_last_name}}"}</PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="patientLastName"
                  value={defaultValue.patientLastName}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.patientLastName)
                      ? alert("Value required!")
                      : addDefaultHandler("row9")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{initial_study_modality}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="initialStudyModal"
                  value={defaultValue.initialStudyModal}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.initialStudyModal)
                      ? alert("Value required!")
                      : addDefaultHandler("row10")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>
                  {"{{initial_exam_location_address}}"}
                </PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="initialLocationAddress"
                  value={defaultValue.initialLocationAddress}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.initialLocationAddress)
                      ? alert("Value required!")
                      : addDefaultHandler("row11")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
            <PersonalizedRow>
              <BottomContainer>
                <PersionalizedText>{"{{exam_date}}"}</PersionalizedText>
              </BottomContainer>
              <PersonalizedFieldSection>
                <InputField
                  type="text"
                  name="examDate"
                  value={defaultValue.examDate}
                  onChange={(e) => handleChange(e)}
                  placeholder="Please add a default"
                />
                <AddButton
                  onClick={() =>
                    isEmpty(defaultValue.examDate)
                      ? alert("Value required!")
                      : addDefaultHandler("row12")
                  }
                >
                  <span>Add</span>
                </AddButton>
              </PersonalizedFieldSection>
            </PersonalizedRow>
          </InsertPersonalizedSection>
        ) : null}
      </CreateTemplateContainer>
    </>
  );
};

export const TemplateButton = ({
  icon,
  title,
  color,
  hoverBg,
  isHover = false,
  clickHandler,
  width,
  style = {},
  iconContainerStyles = {},
  textStyles = {},
  border = false,
  outlinedBr = "",
}) => {
  return (
    <>
      <ButtonContainer
        color={color}
        onClick={clickHandler}
        width={width ? 125 : 120}
        style={style}
        border={border}
        outlinedBr={outlinedBr}
        hoverBg={hoverBg}
        isHover={isHover}
      >
        <Text textcolor={color} style={textStyles}>
          {title}
        </Text>
      </ButtonContainer>
    </>
  );
};

const DatePickerInput = ({ onClick, date, time, isLoading,clickedButton }) => {
  const year = new Date().getFullYear();
  const formatDate = (date, time) => {
    return date
      ? `Send ${moment(date).format(
          'MMM DD, YYYY'
        )} at ${!time ? '--:--' : moment(time).format('HH:mm')} `
      : 'Choose Date & Time';
  };
  return (
    <DatePickerButton onClick={onClick} hoverBg={"#06739D"}>
      <DateContainer hoverBg={"#06739D"}>
      {
        isLoading && clickedButton==='add_future_action'  ?   <Spinner animation="border" role="status" size="sm" /> : <span>{formatDate(date, time)}</span>
      }
      </DateContainer>
    </DatePickerButton>
  );
};

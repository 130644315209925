import React, { useState, useCallback } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { Spinner } from "react-bootstrap";
import {
  deleteSavedCustomChartData,
  getSavedCustomChartData,
  updateSavedCustomChartData,
} from "../../../actions/radminCustomChartData";
import { showAlert } from "../../../actions/radminUploads";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const deleteIconWhite = `${process.env.PUBLIC_URL}/${"delete-icon-white.svg"}`;
const cancelOutline = `${process.env.PUBLIC_URL}/${"cancel-outline.svg"}`;

ReactModal.setAppElement("#root");

const Modal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 386px !important;
  margin-top: 15% !important;
  padding: 20px 24px !important;
  height: ${({ height }) => height};
  background:white;
  margin:0 auto;
  @media (max-width: 768px) {
    width: auto !important;
    height: auto !important;
    margin: 30px !important;
    margin-top: calc(15% + 30px) !important;
  }
`;

const Label = styled.label`
  align-self: flex-start;
  margin: 0px 0px 8px 0;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3f4857;
  margin-top: 20px;
`;

const DeleteHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EditHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const DeleteCustomChartTitle = styled.h2`
  font-size: 24px;
  color: #0c2945;
  font-weight: 700;
  margin-bottom: 0px;
  font-family: Roboto Condensed;
`;
const DeleteBodyLabel = styled.h2`
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 400;
  color: #000000;
`;
const EditBodyLabel = styled.h2`
  font-size: 24px;
  color: #0c2945;
  font-weight: 700;
  margin-bottom: 0px;
  font-family: Roboto Condensed;
`;

const CloseIcon = styled.img`
  cursor: pointer;
  margin-bottom: 0px;
  text-align: right;
  height: 14px;
  display: inline-block;
`;

const DeleteIcon = styled.img`
  cursor: pointer;
  margin-bottom: 0px;
  text-align: right;
  height: 14px;
  display: inline-block;
`;

const DeleteButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
`;

const EditButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;

const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 20px;
`;

const Button = styled.button`
  width: ${({ width }) => (width ? width : "104px")};
  height: 38px;
  background: ${({ isPrimary, disabled, isDelete }) =>
    disabled
      ? isPrimary
        ? "#00AEEF4D":
          "#CF31314D"
      : isPrimary
      ? "#00AEEF"
      : isDelete
      ? "#CF3131"
      : "transparent"};
  border-radius: 5px;
  border: ${({ isPrimary, isDelete }) =>
    isPrimary || isDelete ? "none" : "2px solid #CFCFCF"};
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ isPrimary, isDelete }) =>
    isPrimary || isDelete ? "white" : "#000000"};
`;

const customStyle = {
  overlay: {
      backdropFilter: 'blur(10px)',
      backgroundColor:'rgba(12, 41, 69, 0.38)',
      zIndex:2
  },
  content: {
    borderRadius: "4px",
  },
};

const EditModal = ({
  opened,
  onClose,
  selectedValues,
  setCustomCharts,
  setSelectedChart,
  selectedChart,
}) => {
  const [filterSetName, setFilterSetName] = useState(
    selectedValues?.label || ""
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector((state) => state.auth.user.permissions);
  const updatePermission = rolePermissions
    ?.find((itm) => itm.name === "Custom Charts")
    ?.permissions.find(
      (itm) => itm.codename === "change_customcharts"
    )?.is_authorized;
  const deletePermission = rolePermissions
    ?.find((itm) => itm.name === "Custom Charts")
    ?.permissions.find(
      (itm) => itm.codename === "delete_customcharts"
    )?.is_authorized;

  const onChangeFilterName = (e) => {
    setFilterSetName(e.target.value);
  };

  const handleFilterSave = async (e) => {
    e.preventDefault();
    if ((permission && updatePermission) || !permission) {
      setLoading(true);
      window.analytics.track("Custom-Chart-Edit-Filter-Set-Submit", {
        id: selectedValues.value,
        title: filterSetName,
      });
      await dispatch(
        updateSavedCustomChartData({
          id: selectedValues.value,
          title: filterSetName,
        })
      ).then((res) => {
        if (
          res &&
          selectedChart &&
          selectedChart?.value === selectedValues?.value
        ) {
          setSelectedChart((prev) => ({ ...prev, label: filterSetName }));
        }
        if (res) {
          setCustomCharts((prev) =>
            prev.map((itm) =>
              itm.id === selectedValues?.value
                ? { ...itm, title: filterSetName }
                : itm
            )
          );
        }
      });
      setLoading(false);
      onClose();
    } else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to update custom chart`
        )
      );
    }
  };

  const handleDeleteClicked = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteData = async () => {
    if ((permission && deletePermission) || !permission) {
      window.analytics.track("Custom-Chart-Delete-Filter-Set-Submit", {
        id: selectedValues.value,
      });
      setIsDeleting(true);
      await dispatch(deleteSavedCustomChartData(selectedValues.value)).then(
        (res) => {
          if (
            res &&
            selectedChart &&
            selectedChart?.value === selectedValues?.value
          ) {
            setSelectedChart(null);
          }
          if (res) {
            setCustomCharts((prev) =>
              prev.filter((itm) => itm.id !== selectedValues?.value)
            );
          }
        }
      );

      setIsDeleting(false);
      onClose();
    } else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to delete custom chart`
        )
      );
    }
  };

  const onCloseDeleteView = () => {
    setShowDeleteConfirmation(false);
  };

  const handleEditFiltersRedirect = () => {
    if ((permission && updatePermission) || !permission) {
      window.analytics.track("Custom-Chart-Edit-Filter-Set-Clicked", {
        id: selectedValues.value,
      });
      history.push(
        `/auth/radAdmin/custom/charts/create/${selectedValues.value}`
      );
    } else {
      dispatch(
        showAlert(
          "danger",
          "Error",
          `You don't have permission to update custom chart`
        )
      );
    }
  };

  return (
    <Modal
      height={showDeleteConfirmation ? "175px !important" : "273px !important"}
      isOpen={opened}
      onRequestClose={onClose}
      style={customStyle}
    >
      {showDeleteConfirmation ? (
        <div style={{ width: "100%" }}>
          <DeleteHeaderContainer>
            <DeleteCustomChartTitle>
              Delete Custom Chart?
            </DeleteCustomChartTitle>
            <CloseIcon onClick={onClose} src={cancelOutline} />
          </DeleteHeaderContainer>

          <DeleteBodyLabel>
            Are you sure you want to delete{" "}
            <span style={{ textDecoration: "underline" }}>
              {selectedValues?.label}
            </span>
          </DeleteBodyLabel>
          <DeleteButtonContainer>
            <Button
              width="125px"
              onClick={onCloseDeleteView}
              children="No, Keep It"
            />

            <Button
              width="125px"
              isDelete={true}
              onClick={handleDeleteData}
              disabled={isDeleting}
              children={
                isDeleting ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  <DeleteButton>
                    <span>Yes, Delete It</span>
                  </DeleteButton>
                )
              }
            />
          </DeleteButtonContainer>
        </div>
      ) : (
        <>
          <EditHeaderContainer>
            <EditBodyLabel>Edit Custom Chart</EditBodyLabel>
            <img
              style={{
                cursor: "pointer",
                marginBottom: "0px",
              }}
              onClick={onClose}
              src={cancelOutline}
            />
          </EditHeaderContainer>
          <form style={{ width: "100%" }} onSubmit={handleFilterSave}>
            <Label children="Filter Set Name" htmlFor="filterSet" />
            <Input
              onChange={onChangeFilterName}
              value={filterSetName}
              id="filterSet"
              placeholder="Filter Name"
            />
            <div style={{ marginBottom: "20px" }}>
              <Link
                to={`/auth/radAdmin/custom/charts/create/${selectedValues.value}`}
                style={{
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
                onClick={handleEditFiltersRedirect}
                children="Edit Filters"
              />
            </div>
            <EditButtonContainer>
              <Button style={{ flex: 1 }} onClick={onClose} children="Cancel" />

              <Button
                style={{ flex: 1 }}
                isDelete={true}
                onClick={handleDeleteClicked}
                children={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <span>Delete</span>
                    <DeleteIcon src={deleteIconWhite} />
                  </div>
                }
              />
              <Button
                style={{ flex: 1 }}
                isPrimary={true}
                disabled={!filterSetName || loading}
                type="submit"
                children={
                  loading ? (
                    <Spinner animation="border" role="status" size="sm" />
                  ) : (
                    "Save"
                  )
                }
              />
            </EditButtonContainer>
          </form>
        </>
      )}
    </Modal>
  );
};

export default EditModal;

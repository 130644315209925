import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { saveCohorts, showAlert } from "../../../actions/radminUploads";
import { Spinner } from "react-bootstrap";

const Modal = styled.div`
  display: ${(props) => (props.showModal ? "flex" : "none")};
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  animation: changeWidth 8s ease-in-out infinite;
  background: rgba(12, 41, 69, 0.38);
  backdrop-filter: blur(10px);
  border-right: 1px solid whitesmoke;
`;

const ModalContentContainer = styled.div`
  width: 353px;
  margin: auto;
  border-radius: 4px;
  background-color: white;
  padding: 20px;
`;

const Label = styled.label`
  align-self: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #3f4857;
`;

const Input = styled.input.attrs({ type: "text" })`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c2ccd9;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #8692a4;
  height: 38px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
`;

const Button = styled.button`
  width: ${({ isPrimary }) => (isPrimary ? "48%" : "fit-content")};
  height: ${({ isPrimary }) => (isPrimary ? "38px" : "fit-content")};
  background: ${({ isPrimary, disabled }) =>
    disabled ? "#00AEEF4D" : isPrimary ? "#00AEEF" : "transparent"};
  border-radius: 5px;
  border: none;
  outline: none !important;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${({ isPrimary }) => (isPrimary ? "white" : "#00AEEF")};

  &:hover {
    background-color: ${(props) => (props.disabled ? "" : "#06739D")};
    color: ${(props) => (props.disabled ? "" : "#ffffff")};
  }

  span {
    display: inline-block;
    transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  }
  &:hover span {
    transform: ${(props) => (props.disabled ? "none" : "scale(1.1)")};
    color: ${(props) => (props.disabled ? "#fff" : "#fff")};
  }
`;

function AddToCohort({
  opened,
  onClose,
  selectedFilters,
  modalityOperator,
  anatomyOperator,
  reportModalityOperator,
  setShowAddCohort,
  examCodeOperator
}) {
  const [cohortName, setCohortName] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const permission = useSelector((state) => state.auth.user.permission_level);
  const rolePermissions = useSelector(
    (state) =>state.auth.user.permissions
  );
  const addPermission = rolePermissions?.find(itm=>itm.name ==="Follow-Ups")?.permissions.find(itm=>itm.codename === "add_follow-ups")?.is_authorized

  const onChangeFilterName = (e) => {
    setCohortName(e.target.value);
  };

  const handleCohortSave = async (e) => {
    e.preventDefault();
    if((permission && addPermission) || !permission){
      let list = Object.values(selectedFilters)
      .map((item, index) => {
        if (!item) return null;
        return item;
      })
      .filter(Boolean)
      .flat(10);

  
    window.analytics.track("Follow-Up-List-Add-To-Cohort-Submit", {
      title: cohortName,
      list: list,
    });
    setLoading(true);
    await dispatch(
      saveCohorts({
        title: cohortName,
        list: list.filter(
          (itm) => itm.category !== "cohort"
        ),
        modalityOperator,
        anatomyOperator,
        reportModalityOperator,
        examCodeOperator
      })
    );
    setShowAddCohort(false);
    setLoading(false);
    onClose();
    }else{
      dispatch(
        showAlert('danger', 'Error', `You don't have permission to create cohort`)
      );
    }     
   
  };

  return (
    <Modal showModal={opened}>
      <ModalContentContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h2
            style={{
              fontSize: "18px",
              color: "#0C2945",
              fontWeight: 700,
              fontFamily: "Roboto Condensed",
            }}
          >
            Cohort Details
          </h2>
          <p style={{ cursor: "pointer" }} onClick={onClose}>
            x
          </p>
        </div>

        <form style={{ width: "100%" }} onSubmit={handleCohortSave}>
          <Label children="Cohort Name" htmlFor="cohortName" />
          <Input
            onChange={onChangeFilterName}
            value={cohortName}
            id="cohortName"
            placeholder="Cohort Name"
          />
          <ButtonContainer>
            <Button
              type="submit"
              isPrimary={true}
              disabled={!cohortName}
              children={loading? <Spinner animation="border" role="status" size="sm" /> : <span>Submit</span>}
            />
          </ButtonContainer>
        </form>
      </ModalContentContainer>
    </Modal>
  );
}
export default AddToCohort;

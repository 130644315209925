
import { desiredOrderAppointments, desiredOrderEmails, desiredOrderFaxes, desiredOrderNotes, desiredOrderPrescriptions, desiredOrderRecoHistory, desiredOrderRecommendations, desiredOrderReports, desiredOrderSms } from "./Constants";

 export const flattenIndividualArray = (selectedValues,data, flattenData,uniqueStatuses)=>{
        flattenData = data?.map((item) => {
          const obj = {};
          if (item?.data?.length > 0) {
            item.data.forEach((d) => {
              obj[
                selectedValues["group_by"]
                  ?.sort((a, b) => {
                    let desiredOrderArray = "";
                    if (selectedValues["data_type"]?.value === "prescriptions") {
                      desiredOrderArray = desiredOrderPrescriptions;
                    }
                    if (selectedValues["data_type"]?.value === "appointments") {
                      desiredOrderArray = desiredOrderAppointments;
                    }
                    if (selectedValues["data_type"]?.value === "sms") {
                      desiredOrderArray = desiredOrderSms;
                    }
                    if (selectedValues["data_type"]?.value === "emails") {
                      desiredOrderArray = desiredOrderEmails;
                    }
                    if (selectedValues["data_type"]?.value === "reco-history") {
                      desiredOrderArray = desiredOrderRecoHistory;
                    }
                    if (selectedValues["data_type"]?.value === "recommendations") {
                      desiredOrderArray = desiredOrderRecommendations;
                    }
                    if (selectedValues["data_type"]?.value === "notes") {
                      desiredOrderArray = desiredOrderNotes;
                    }
                    if (selectedValues["data_type"]?.value === "reports") {
                      desiredOrderArray = desiredOrderReports;
                    }
                    if (selectedValues["data_type"]?.value === "faxes") {
                      desiredOrderArray = desiredOrderFaxes;
                    }
    
    
                    return (
                      desiredOrderArray.indexOf(a.label) -
                      desiredOrderArray.indexOf(b.label)
                    );
                  })
                  .map((itm) => d[itm.value] || "empty")
                  .join(" - ")
              ] = d[ "id__count"
              ];
            });
          }
          return { date: item.date, ...obj };
        });
    
        uniqueStatuses = [
          ...new Set(
            data?.flatMap((item) =>
              item.data?.map((d) => {
                return selectedValues["group_by"]
                  ?.sort((a, b) => {
                    let desiredOrderArray = "";
                    if (selectedValues["data_type"]?.value === "prescriptions") {
                      desiredOrderArray = desiredOrderPrescriptions;
                    }
                    if (selectedValues["data_type"]?.value === "appointments") {
                      desiredOrderArray = desiredOrderAppointments;
                    }
                    if (selectedValues["data_type"]?.value === "sms") {
                      desiredOrderArray = desiredOrderSms;
                    }
                    if (selectedValues["data_type"]?.value === "emails") {
                      desiredOrderArray = desiredOrderEmails;
                    }
                    if (selectedValues["data_type"]?.value === "reco-history") {
                      desiredOrderArray = desiredOrderRecoHistory;
                    }
                    if (selectedValues["data_type"]?.value === "recommendations") {
                      desiredOrderArray = desiredOrderRecommendations;
                    }
                    if (selectedValues["data_type"]?.value === "notes") {
                      desiredOrderArray = desiredOrderNotes;
                    }
                    if (selectedValues["data_type"]?.value === "reports") {
                      desiredOrderArray = desiredOrderReports;
                    }
                    if (selectedValues["data_type"]?.value === "faxes") {
                      desiredOrderArray = desiredOrderFaxes;
                    }
    
                    return (
                      desiredOrderArray.indexOf(a.label) -
                      desiredOrderArray.indexOf(b.label)
                    );
                  })
                  .map((itm) => d[itm.value] || "empty")
                  .join(" - ");
              })
            )
          ),
        ];
      

        return {flattenData,uniqueStatuses}
    
 }
 

 export const flattenCompareArray = (data, flattenedData) => {
  flattenedData = data?.flatMap((group) =>
    group?.data?.map((item) => {
      const statusCounts = item?.data?.reduce((acc, statusItem) => {
        const label = group.label || "Unknown Label";
        let status = [];

        Object.keys(statusItem).forEach((key) => {
          if (
            statusItem[key] || 
            statusItem[key] === null || 
            statusItem[key] === ""
          ) {
            status.push(statusItem[key] || "empty");
          }
        });

        const statusString = status.join(" - ");

        acc[`${label} (${statusString})`] =
          (acc[`${label} (${statusString})`] || 0) +
          (statusItem.hasOwnProperty("id__count") && statusItem.id__count !== undefined
            ? statusItem?.id__count
            : statusItem?.imagine_metadata__charge_amount__sum);
        return acc;
      }, {});

      return {
        date: item.date,
        category: group.category,
        ...statusCounts,
      };
    })
  );

  return { flattenedData };
};


 export const groupStatusesByCategory = (selectedValues,statuses, statusColors) => {
    return statuses.reduce((acc, status) => {
      const [category, statusName] = status.split(" (");
      const cleanStatus = statusName ? statusName.replace(")", "") : "unknown";

      if (!acc[category]) {
        acc[category] = [];
      }

      if (category.includes("Reports:") && !statusName) {
        acc[category].push({
          status:
            selectedValues["data_subset"]["reports"]?.value === "charge"
              ? "Sum"
              : "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Appointments:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Prescriptions:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Emails:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Notes:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Sms:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Recommendations:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Reco-history:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } else if (category.includes("Faxes:") && !statusName) {
        acc[category].push({
          status: "Count",
          color: statusColors[status],
        });
      } 
       else {
        acc[category].push({
          status: cleanStatus,
          color: statusColors[status],
        });
      }

      return acc;
    }, {});
  };


 export const pieChartData = (data) => {
  const processedData = [];
  let othersCount = 0;

  data.forEach((item, index) => {
    const label = Object.keys(item)
      .filter((key) => key !== "id__count") 
      .map((key) => String(item[key] ?? "Empty"))
      .join(" ") 
      .trim(); 

    if (index < 5) {
      processedData.push({
        label: label || "Unknown", 
        name: label || "Unknown",
        id__count: item.id__count,
      });
    } else {
      othersCount += item.id__count;
    }
  });

  if (othersCount > 0) {
    processedData.push({
      label: "Others",
      name: "Others", 
      id__count: othersCount,
    });
  }

  return processedData;
};



export const pieChartDataTable = (data) => {
  return data.map((item) => {
    const label = Object.keys(item)
      .filter((key) => key !== "id__count") 
      .map((key) => String(item[key] ?? "Empty"))
      .join(" ") 
      .trim();

    return {
      label: label || "Unknown", 
      name: label || "Unknown",
      id__count: item.id__count,
    };
  });
};

import moment from "moment";
import API from "../../libs/api";
import { showAlert } from "../radminUploads";
import { capitalize } from "lodash";

export const getCustomChartData =
  (selectedValues, startDate, endDate, filterValue, chartId) =>
  async (dispatch) => {
    try {

      if (chartId) {
        const { data } = await API.getRequest(
          `/api/v1/chart-builder-filters/${chartId}/aggregate/`
        );

        return data;
      } else {
        const { data } = await API.postRequest(
          `/api/v1/chart-builder-filters/aggregate/`,
          {
            ...selectedValues,
            timeframe: filterValue,
            startDate: startDate,
            endDate: endDate,
          }
        );

        return data;
      }
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

export const saveCustomChartData = (data, id) => async (dispatch) => {
  try {
    if (id) {
      await API.patchRequest(`/api/v1/chart-builder-filters/${id}/`, data);
    } else {
      await API.postRequest("/api/v1/chart-builder-filters/", data);
    }
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const getSavedCustomChartData = () => async (dispatch) => {
  try {
    const { data } = await API.getRequest(
      `/api/v1/chart-builder-filters/?page_size=500&ordering=-modified`
    );
    return data;
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const deleteSavedCustomChartData =
  (id) => async (dispatch, getState) => {
    try {
      await API.deleteRequest(`/api/v1/chart-builder-filters/${id}/`);
      dispatch(
        showAlert("success", "Success", "Custom chart deleted successfully!")
      );
      return true;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

export const updateSavedCustomChartData =
  ({ id, title }) =>
  async (dispatch, getState) => {
    try {
      await API.patchRequest(`/api/v1/chart-builder-filters/${id}/`, {
        title: title,
      });
      dispatch(
        showAlert("success", "Success", "Custom chart updated successfully!")
      );
      return true;
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

export const getSavedCustomChartDataWithId = (id) => async (dispatch) => {
  try {
    const { data } = await API.getRequest(
      `/api/v1/chart-builder-filters/${id}/`
    );
    return data;
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

export const customChartEmailSubscription =
  (frequency, chart_builder) => async (dispatch) => {
    try {
      if (chart_builder) {
        let { data } = await API.postRequest(
          `/api/v1/chart-builder-subscriptions/`,
          {
            chart_builder,
            frequency,
          }
        );
        dispatch(
          showAlert(
            "success",
            "Success",
            `${
              frequency == "never"
                ? `Custom chart  email successfully unsubscribed!`
                : `Custom chart ${frequency} email successfully subscribed!`
            }`
          )
        );
        return data;
      }
    } catch (error) {
      dispatch(showAlert("danger", "Error", error.message));
    }
  };

export const publishCustomChartData = (id, publishing) => async (dispatch) => {
  try {
    if (id) {
      let { data } = await API.patchRequest(
        `/api/v1/chart-builder-filters/${id}/`,
        {
          is_published: publishing,
        }
      );
      dispatch(
        showAlert(
          "success",
          "Success",
          `${
            publishing
              ? "Custom chart published successfully!"
              : "Custom chart unpublished successfully!"
          }`
        )
      );
      return data;
    }
  } catch (error) {
    dispatch(showAlert("danger", "Error", error.message));
  }
};

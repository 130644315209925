import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Box from "../../../components/_styles/Box";
import { Card } from "../../../styles";
import Flex from "../../../components/_styles/Flex";
import PatientTable from "./PatientListView/PatientTable";
import { EmptyState } from "../../../components/EmptyState/EmptyState";
import LoadMoreButton from "../../../components/LoadMoreButton";
import { ReactComponent as EmptyStateImg } from "../../../assets/empty-state-search.svg";
import { ReactComponent as Search } from "../../../assets/searchIconGrey.svg";

import {
  getExportReportAction,
  getPatientsListViewAction,
  SET_PATIENTS_SEARCH_VALUE,
} from "../../../actions/radminUploads";
import { useFormik } from "formik";
import EnableFeature from "../../../components/EnableFeature/EnableFeature";
import { useLocation } from "react-router";
import filterOutline from "../../../assets/IO-icons/filter-outline.svg";
import { months, PatientListFilters } from "./PatientListFilters";
import close from "../../../assets/cancel-outline-chip.svg";
import { Button, makeStyles } from "@material-ui/core";
import { Spinner } from "react-bootstrap";

const cancelIcon = `${process.env.PUBLIC_URL}/${"cancel-circled.svg"}`;

const FormContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 57px;
  align-item: space-between;
`;
const InputContainer = styled.div`
  // width: 100vw;
  // padding: 0px 20px 0px 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
`;
const SearchIcon = styled.i`
  display: inline-block;
  position: absolute;
  color: green;
  min-width: 40px;
  left: 10px;
`;
const CustomPlaceholder = styled.span`
  position: absolute;
  // margin-left: 100px;
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #666666;
  left: 30px;
`;
const Input = styled.input`
  width: 700px;
  background: white;
  border: 1px solid #d2d3d7;
  border-radius: 5px;
  height: 38px;
  font: normal 16px "Roboto Condensed", sans-serif;
  padding-left: 35px;
`;

const ClearButton = styled.button`
  position: absolute;
  border-radius: 5%;
  z-index: 1;
  border: none;
  cursor: pointer;
  color: #000000;
  background-color: transparent;
  transform: translateX(2px);
  right: 10px;
`;

const PaginationButton = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #dfe0eb;
  padding-top: 30px;
  padding-bottom: 30px;
`;

const Header = styled.h1`
  font-size: 36px;
  color: #0c2945;
  font-weight: 700;
  font-family: Roboto Condensed;
  margin-bottom: 0px;
`;

const FilterButton = styled(Flex)`
  width: 41px;
  height: 38px;
  justify-content: space-around;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #00aeef;
  background: #fff;
  cursor: pointer;
  border: 1px solid #dfe0eb;
  border-radius: 4px;
`;

const SelectedItemContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
  min-width: fit-content;
  height: 32px;
  margin-top: 2px;
  padding: 4px 10px 4px 10px;
  background: #eeeeee;
  border-radius: 25px;
  @media (max-width: 768px) {
    width: 120px;
  }
`;

const Image = styled.img`
  display: block;
  width: 20px;
`;

const SelectedItem = styled.div`
  margin: 7px 3px 6px 8px;
  min-width: 50px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  color: #a1a1a1;
`;

const CloseSign = styled.div`
  width: 7px;
  height: 7px;
  color: #00aeef;
  margin-right: 7px;
  opacity: 1;
  &:hover {
    cursor: pointer;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: ${(props) => (props.space ? props.space : "space-between")};
  gap: ${(props) => (props.gap ? props.gap : "25px")};
  align-items: ${(props) => (props.alignment ? props.alignment : "center")};
`;

const useStyles = makeStyles({
  label: {
      padding: "5px 0px",
      fontSize: "12px",
      fontWeight: "bold"
  },
  exportButton: {
      color: "#FFFFFF",
      backgroundColor: "#00AEEF",
      textTransform: 'none',
      border: "2px solid transparent",
      width: '8rem',
      "&:hover": {
          backgroundColor: "transparent",
          border: "2px solid #00AEEF",
          color: "#00AEEF",
        },
  }
})

const PatientList = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const itemsPortionSize = 10;
  const [displayItemsCount, setDisplayItemsCount] = useState(itemsPortionSize);
  const [pageNumber, setPageNumber] = useState(1);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [isActiveFirst, setIsActiveFirst] = useState(
    history.location.pathname.split("/")[4] === "patients"
  );
  const inputRef = useRef(null);
  const patients = useSelector((state) => state.radAdmin.patientsList);
  const patientsLoading = useSelector(
    (state) => state.radAdmin.patientsList.isLoading
  );
  const hasMoreItems = patients?.data?.length !== patients.totalCount;
  const location = useLocation();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    day: "",
    month: "",
    year: "",
  });
  const [loading, setLoading] = useState(false);

  const convertedPatientQuery =
    "reco_status=adherent&reco_status=scheduled&reco_status=rx_received&apply_attribution_window=true&message_sent=true";

  const getSerializedQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.toString();
  };

  let initialQueryString = getSerializedQueryParams();

  const onHidePlaceholder = () => {
    if (isActiveFirst) {
      setShowPlaceholder(false);
      return;
    }
  };

  const onShowPlaceholder = () => {
    if (!inputRef.current.value) {
      setShowPlaceholder(true);
    }
  };

  const handleExportCohort = () => {   
    setLoading(true);
    const payload = {
      recommendation: ['adherent','scheduled_not_completed','rx_received'],
      messageSent: true,
      applyAttributionWindow: true
    }
    dispatch(getExportReportAction('', '', payload)).then(res=>{
      setLoading(false);
    })
  }

  const formik = useFormik({
    initialValues: {
      searchValue: "",
      searchValueRad: "",
    },
    onSubmit: (values) => {
      if (isActiveFirst) {
        setPageNumber(1);
        dispatch({
          type: SET_PATIENTS_SEARCH_VALUE,
          payload: values.searchValue,
        });
        const params = new URLSearchParams(location.search);
        if (values.searchValue) {
          params.set("search", values.searchValue);
        } else {
          params.delete("search");
        }
        let queryString = params.toString();
        dispatch(getPatientsListViewAction(1, queryString));
        history.push(`/auth/radAdmin/followUp/patients?${queryString}`);
        return;
      }
    },
  });

  const onResetForm = () => {
    if (isActiveFirst) {
      formik.setFieldValue("searchValue", "");
      const params = new URLSearchParams(location.search);
      params.delete("search");
      let queryString = params.toString();
      dispatch(getPatientsListViewAction(1, queryString));

      history.push(`/auth/radAdmin/followUp/patients/?${queryString}`);
      setShowPlaceholder(true);
    }
    setPageNumber(1);
  };

  const onLoadMore = () => {
    if (hasMoreItems) {
      dispatch(getPatientsListViewAction(pageNumber + 1, initialQueryString));
      setPageNumber((prevNumber) => prevNumber + 1);
      setDisplayItemsCount(displayItemsCount + itemsPortionSize);
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(history.location.search);
    let yearFilter = query.get("date_of_birth_year");
    let monthFilter = query.get("date_of_birth_month");
    let dateFilter = query.get("date_of_birth_day");
    setSelectedFilters({
      year: yearFilter
        ? [{ value: yearFilter, label: yearFilter, category: "year" }]
        : "",
      month: monthFilter
        ? [
            {
              value: monthFilter,
              label: months.find((itm) => itm?.value === monthFilter)?.label,
              category: "month",
            },
          ]
        : "",
      day: dateFilter
        ? [{ value: dateFilter, label: dateFilter, category: "day" }]
        : "",
    });
    let search = query.get("search");
    if (search) {
      formik.setFieldValue("searchValue", search);
      setShowPlaceholder(false);
      dispatch(getPatientsListViewAction(1, initialQueryString));
    } else {
      dispatch(getPatientsListViewAction(1, initialQueryString));
    }
  }, []);

  const closeSelected = (category, selectedItem) => {
    setPageNumber(1);
    const filters = selectedFilters[category].filter((item) => {
      return item?.value !== selectedItem?.value;
    });
    let newFilters = {
      ...selectedFilters,
      [category]: filters.length > 0 ? filters : [],
    };
    setSelectedFilters(newFilters);

    const params = new URLSearchParams(location.search);
    params.delete("date_of_birth_year");
    params.delete("date_of_birth_month");
    params.delete("date_of_birth_day");
    if (newFilters.year.length > 0) {
      params.append("date_of_birth_year", newFilters.year[0]?.value);
    }
    if (newFilters.month.length > 0) {
      params.append("date_of_birth_month", newFilters.month[0]?.value);
    }
    if (newFilters.day.length > 0) {
      params.append("date_of_birth_day", newFilters.day[0]?.value);
    }
    let queryString = params.toString();
    dispatch(getPatientsListViewAction(1, queryString));

    history.replace({
      search: params.toString(),
    });
  };

  return (
    <Box p={"32px 50px"}>
      <FormContainer>
        <Header>
          {initialQueryString.includes(convertedPatientQuery)
            ? "Converted Patients: "
            : "Patients: "}{" "}
          {(!patientsLoading || (patientsLoading && pageNumber !== 1)) &&
            patients.totalCount}
        </Header>
        {isActiveFirst && (
          <div style={{ display: "flex", gap: "10px" }}>
            <FilterButton onClick={() => setShowFilters(true)}>
              <Image src={filterOutline} alt={"settings icon"} />
            </FilterButton>
            <form onSubmit={formik.handleSubmit}>
              <InputContainer>
                <SearchIcon>
                  <Search />
                </SearchIcon>
                {showPlaceholder && (
                  <>
                    <CustomPlaceholder onClick={onHidePlaceholder}>
                      {"Search by Patient Name, MRN, Email, or Phone Number"}
                    </CustomPlaceholder>
                  </>
                )}
                <Input
                  type="text"
                  id={"searchValue"}
                  name={"searchValue"}
                  onFocus={onHidePlaceholder}
                  onBlur={onShowPlaceholder}
                  onChange={formik.handleChange}
                  value={formik.values.searchValue}
                  ref={inputRef}
                />
                {!showPlaceholder && formik.values.searchValue && (
                  <ClearButton type={"reset"} onClick={onResetForm}>
                    <img src={cancelIcon} />
                  </ClearButton>
                )}
              </InputContainer>
            </form>
          </div>
        )}

      </FormContainer>

{
  initialQueryString.includes(convertedPatientQuery) && (

    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      <Button
        classes={{ label: classes.label, root: classes.exportButton }}
        onClick={handleExportCohort}
        style={{cursor:loading?'not-allowed': 'pointer'}}
      >
       {loading?   <Spinner animation="border" role="status" size="sm" /> : 'Export' } 
      </Button>
    </div>

  )
}


      {Object.values(selectedFilters).some((itm) => itm.length > 0) && (
        <Row
          style={{
            justifyContent: "flex-end",
            marginBottom: "20px",
            gap: "6px",
            flexWrap: "wrap",
          }}
        >
          {Object.values(selectedFilters).map((item, index) => {
            if (!item || !Array.isArray(item)) return null;
            return item.map((i, idx) => (
              <SelectedItemContainer key={index + idx}>
                <SelectedItem>
                  {" "}
                  {i?.category === "year"
                    ? "Patient DOB Year"
                    : i?.category === "month"
                    ? "Patient DOB Month"
                    : i?.category === "day"
                    ? "Patient DOB Day"
                    : ""}
                  {": "}
                  {i?.label?.replace(/\b\w/g, function (char) {
                    return char.toUpperCase();
                  })}
                </SelectedItem>
                <CloseSign onClick={() => closeSelected(i.category, i)}>
                  <Image src={close} alt={"close sign"} />
                </CloseSign>
              </SelectedItemContainer>
            ));
          })}
        </Row>
      )}
      <Card>
        <PatientTable
          patientsData={patients.data}
          isLoading={patientsLoading && pageNumber === 1}
          needMoreDetails={false}
          noDataRender={() => (
            <EmptyState title={"No Results"} img={() => <EmptyStateImg />}>
              Sorry, there are no results
            </EmptyState>
          )}
        />

        {patients.data && patients?.data.length !== 0 && (
          <PaginationButton>
            <LoadMoreButton
              title="Load more patients"
              handleClick={onLoadMore}
              isDisabled={!hasMoreItems}
              isLoading={patients?.isLoading}
            />
          </PaginationButton>
        )}
      </Card>
      {showFilters && (
        <PatientListFilters
          showModal={showFilters}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
          onClose={() => setShowFilters(false)}
          handleSave={(filters) => {
            if (isActiveFirst) {
              setPageNumber(1);
              const params = new URLSearchParams(location.search);
              params.delete("date_of_birth_year");
              params.delete("date_of_birth_month");
              params.delete("date_of_birth_day");
              if (filters.year.length > 0) {
                params.append("date_of_birth_year", filters?.year[0]?.value);
              }
              if (filters.month.length > 0) {
                params.append("date_of_birth_month", filters?.month[0]?.value);
              }
              if (filters.day.length > 0) {
                params.append("date_of_birth_day", filters?.day[0]?.value);
              }
              let queryString = params.toString();
              dispatch(getPatientsListViewAction(1, queryString));

              history.replace({
                search: params.toString(),
              });
            }
          }}
        />
      )}
    </Box>
  );
};

export default PatientList;

export const PatientViewWithFeature = (props) => {
  return (
    <EnableFeature featureName={"enable-patient-view-list"} {...props}>
      <PatientList />
    </EnableFeature>
  );
};
